import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL_BASE + 'api/admin/events/[eventId]/checkpoints/[checkpointId]/knowbarcodes/';

class KnowBarcodeService{
    getKnowBarcodes(eventId, checkpointId, filter){
        if(!eventId) throw new Error('Error: eventId is null');
        if(!checkpointId) throw new Error('Error : checkpointId is null');

        var url = API_URL.replace('[eventId]', eventId).replace('[checkpointId]',checkpointId);
        if(filter){
            url += "?filter=" + filter;
        }
        return axios.get(url, { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getKnowBarcodeIds(eventId, checkpointId){
        if(!eventId) throw new Error('Error: eventId is null');
        if(!checkpointId) throw new Error('Error : checkpointId is null');

        var url = API_URL.replace('[eventId]', eventId).replace('[checkpointId]',checkpointId);
        return axios.get(url + 'ids/', { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getKnowBarcodeById(eventId, checkpointId, barcodeId){
        if(!eventId) throw new Error('Error: eventId is null');
        if(!checkpointId) throw new Error('Error : checkpointId is null');
        if(!barcodeId) throw new Error('Error : barcodeId is null');
        
        var url = API_URL.replace('[eventId]', eventId).replace('[checkpointId]',checkpointId);
        return axios.get(url + 'id/' + barcodeId, { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getKnowBarcodeByBarcode(eventId, checkpointId, barcode){
        if(!eventId) throw new Error('Error: eventId is null');
        if(!checkpointId) throw new Error('Error : checkpointId is null');
        if(!barcode) throw new Error('Error : barcode is null');

        var url = API_URL.replace('[eventId]', eventId).replace('[checkpointId]',checkpointId);
        return axios.get(url + 'barcode/' + barcode, { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getKnowbarcodeByIdsRange(eventId, checkpointId, fromId, toId){
        if(!eventId) throw new Error('Error: eventId is null');
        if(!checkpointId) throw new Error('Error : checkpointId is null');
        if(!fromId) throw new Error('Error: fromId is null');
        if(!toId) throw new Error('Error: toId is null');

        var url = API_URL.replace('[eventId]', eventId).replace('[checkpointId]',checkpointId);
        url += 'ids/from/' + fromId + '/to/' + toId
        return axios.get(url, { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getKnowbarcodeByPage(model, eventId, checkpointId, filter){
        if(!eventId){
            throw new Error('eventId is null.');
        }
        if(!checkpointId){
            throw new Error('checkpointId is null.');
        }
        if(!model.page & model.page != 0){
            throw new Error('page is null.');
        }
        if(!model.amount){
            throw new Error('amount is null.');
        }

        var url = API_URL.replace('[eventId]', eventId).replace('[checkpointId]', checkpointId);
        if(filter){
            url += "page/?filter=" + filter;
        }
        else{
            url += "page/"
        }
        return axios.put(url, model,{ headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    syncKnowbarcodes(eventId, checkpointId){
        if(!eventId) throw new Error('Error: eventId is null');
        if(!checkpointId) throw new Error('Error : checkpointId is null');

        var url = API_URL.replace('[eventId]', eventId).replace('[checkpointId]',checkpointId);
        return axios.get(url + 'sync/', { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    exportAllScans(eventId){
        if(!eventId) throw new Error('Error: eventId is null');

        var url = API_URL.replace('[eventId]', eventId).replace('[checkpointId]', 0);
        return axios.get(url + 'publish-all/', { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }
}

export default new KnowBarcodeService;