<template>
    <b-modal v-model="show" v-bind:title="$t('deleteUser')" @ok="deleteUser">
        {{ $t("comfirmDelete") }} {{user.name}} ? 

        <template #modal-footer>
            <div class="w-100">
                <button type="button" @click="show = false" class="btn btn-outline-secondary mx-1">{{$t("cancel")}}</button>
                <button type="button" @click="$event => deleteUser()" class="btn btn-danger mx-1">{{$t('delete')}}</button>
            </div>
        </template>
    </b-modal>
</template>
<script>
import UserService from '../../services/user.service';
import utilMixin from '../../mixins/utils.mixins';

export default {
    name:"DeleteConfirmUser",
    mixins: [utilMixin],
    data : function() {
        return {
            show: false,
            user: {}
        };
    },
    created : function(){
    },
    mounted: function() {
    },
    methods: {                
        showModal: function(){
            this.show = true;
        },
        deleteUser: function(){
            UserService.deleteUser(this.user).then(
                resp => {
                    var response = resp.status ? resp : resp.response;
                    this.show = false;
                    if(response.status == 200){
                        
                        this.showToast(this.$t('deleteUser'), response.data);
                        this.$emit("update_ok");
                    } 
                    else {
                        
                        this.showToast(this.$t('error'), response.data,'danger');
                    }
                }
            );
        }
    },
    computed: {
    }
}
</script>