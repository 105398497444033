<template>
    <div class="tab-content">
        <section id="infos" class="tab-pane fade show active" role="tabpanel" aria-labelledby="tab-infos">
            <h4 class="d-md-inline-block line line-white my-1 text-primary-dark"><font-awesome-icon icon="address-card" class="mr-2 text-success" style="font-size: 22px;"/>{{ $t('profile') }}</h4>
            
            <form class="readonly">
                <div class="form-group row">
                    <label class="col-12 col-sm-4 col-form-label">{{ $t('name') }}</label>
                    <div class="col-12 col-sm-8">
                        <input type="text" class="form-control" v-bind:value="userName" disabled="disabled">
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-12 col-sm-4 col-form-label">{{ $t('role') }}</label>
                    <div class="col-12 col-sm-8" v-for="(role, index) in roles" v-bind:key="index">
                        <input type="text" class="form-control" v-bind:value="role" disabled="disabled">
                    </div>
                </div>
            </form>
        </section>
    </div>
</template>

<script>
    export default {
        name: 'ProfileInfo',
        props: ['value'],
        methods: {
            close: function () {
                this.$emit('input', false);
            }
        },
        computed: {
            userName: function(){
                return this.$store.state.auth.user ? this.$store.state.auth.user.name : null;
            },
            roles : function(){
                return this.$store.state.auth.user ? this.$store.state.auth.user.roles : null;
            }
        }
    };
</script>

<style scoped>
    
</style>