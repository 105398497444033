<template>
    <b-modal v-if="modalShow && eventName != ''" v-model="modalShow" v-bind:title="$t('eventConfig') + eventName" size="lg">
        <div class="k4-eventConfig__modal__body">
            <div class="row">
                <div class="col-4">{{ $t("timezoneSelect") }}</div>
                <div class="col-8">
                    <div>
                        <VueMultiselect
                            v-model="eventTimeZone"
                            :options="timezones"
                            :close-on-select="true"
                            :disabled="dateDisabled">
                        </VueMultiselect>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t("RobotUserEnable") }}</div>
                <div class="col-8" text-primary>
                    <div class="p-1 k4-pointer" @click="robotEnabled = !robotEnabled">
                        <span class="k4-checkbox" v-if="robotEnabled"><font-awesome-icon :icon="['fas','check-square']" size="lg" /></span>
                        <span class="k4-checkbox" v-if="!robotEnabled"><font-awesome-icon :icon="['fas','square']" size="lg" /></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">{{ $t("pdfBanner") }}<label style="font-size: 11px">{{ $t("pdfBannerInfo") }}</label></div>
                <div class="col-8">
                    <input type="file" id="input" accept="image/png" @change='imageChange'>
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="w-100">
                <button type="button" @click="modalShow = false" class="btn btn-outline-secondary mx-1">{{$t("cancel")}}</button>
                <button type="button" @click="$event => save()" class="btn btn-success mx-1">{{$t('save')}}</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import EventService from '../../services/event.service';
    import utilMixin from '../../mixins/utils.mixins';
    import VueMultiselect from 'vue-multiselect'

    export default {
        name : 'eventConfig',
        mixins: [utilMixin],
        components: { VueMultiselect },
        data : function(){
            return {
                eventName : '',
                eventTimeZone:'',
                timezones : [],
                modalShow : false,
                dateDisabled : false,
                imageUploaded : "",
                robotEnabled : false
            };
        },
        computed: {
            eventId : function() {
                return this.$route.params.id;
            }
        },
        created: function(){
            EventService.getAllTimezones(this.eventId).then(
                resp => {
                    var response = resp.status ? resp : resp.response;
                    if(response.status == 200){
                        this.timezones = response.data;
                        this.modalShow = true;
                        this.selectActualTimezone();
                    } else {
                        
                        this.showToast(this.$t('error'), response.data, 'danger');
                    }
                }
            ).catch(error => {
                
                this.showToast(this.$t('error'), error);
            })
        },
        methods:{
            showModal : function() {
                this.refreshEventDetails();
                this.modalShow = true;
            },
            imageChange : function() {
                var fileInput = document.getElementById('input');
                var file = fileInput.files[0];
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    this.resizeImage(reader.result).then((result) => {
                        this.imageUploaded = result;
                    }).bind(this);
                }.bind(this);
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            },
            resizeImage : function(base64Str) {
                return new Promise((resolve) => {
                    let img = new Image()
                    img.src = base64Str
                    img.onload = () => {
                        let canvas = document.createElement('canvas')
                        const MAX_WIDTH = 725
                        const MAX_HEIGHT = 150
                        canvas.width = MAX_WIDTH
                        canvas.height = MAX_HEIGHT
                        let ctx = canvas.getContext('2d')
                        ctx.drawImage(img, 0, 0, MAX_WIDTH, MAX_HEIGHT)
                        resolve(canvas.toDataURL())
                    }
                })
            },
            save : function(){
                var model = {
                    timezone : this.eventTimeZone, 
                    robotEnabled : this.robotEnabled,
                    image64 : this.imageUploaded.replace('data:image/png;base64,', '')
                }
                console.log('model to send : ' + JSON.stringify(model));
                EventService.updateConfig(this.eventId, model).then(
                    resp =>{
                        var response = resp.status ? resp : resp.response;
                        this.modalShow = false;
                        if(response.status == 200){
                            this.showToast(this.$t('saveEvent'), response.data);
                            this.$emit('synchroEvent');
                        }
                        else {
                            
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                ).catch(
                    error => {
                        this.modalShow = false;
                        
                        this.showToast(this.$t('error'), error, 'danger');
                    }
                );
                this.$emit('save_ok');
            },
            refreshEventDetails : function() {
                EventService.getEventDetails(this.eventId).then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        if(response.status == 200){
                            this.eventName = response.data.name;
                            this.eventTimeZone = response.data.timezoneIso;
                            var today = new Date();
                            var startDate = new Date(response.data.startDate.replace('T', ' '));
                            if (startDate < today){
                                this.dateDisabled = true;
                            }
                            this.robotEnabled = response.data.acceptRobot;
                        } else {
                            
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                ).catch(error => {
                    
                    this.showToast(this.$t('error'), error);
                })
            }
        }
    }
</script>

<style scoped>
    .k4-eventConfig__modal__body {
        min-height: 50vh;
    }
    .k4-checkbox { color:var(--main-k4-primary-color); }
</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>