import { DateTime } from "luxon";

export default {
    GetDateFromISO(isoDateTimeString){ // isoDateTimeString = 1983-10-14
        return DateTime.fromISO(isoDateTimeString, { setZone: true }).toLocaleString(DateTime.DATE_SHORT); // 14/10/1983
    },
    
    GetDateTimeFromISO(isoDateTimeString, isWithSecondBool = true){
        if(isWithSecondBool){
            return DateTime.fromISO(isoDateTimeString, { setZone: true }).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS); // 14/10/1983 à 13:30:23
        }
        else {
            return DateTime.fromISO(isoDateTimeString, { setZone: true }).toLocaleString(DateTime.DATETIME_SHORT); // 14/10/1983 à 13:30
        }
    }
}