<template>
    <div>
        <b-modal v-model="modalShow" v-bind:title="$t('activateFor') + user.name + ' (robot)'" size="xl">
            <div class="k4-modal__body container-fluid">
                <div class="row pb-2">
                    <div class="col-lg-4">{{ $t("name") }}</div>
                    <div class="col-lg-8"><input type="text" class="form-control" v-model="user.name" disabled></div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-4">{{ $t("pinCode") }}</div>
                    <div class="col-lg-8"><input type="text" class="form-control" v-model="user.pin" disabled></div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-4">{{ $t("dayOfWork") }}</div>
                    <div class="col-lg-8">
                        <b-form-datepicker v-model="dateOfWork" hide-header class="mb-2" dropup menu-class="w-100" calendar-width="100%"></b-form-datepicker>
                    </div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-4">{{ $t("checkpoints")}}</div>
                    <div class="col-lg-8 text-primary"></div>    
                </div>
                <div class="row">
                    <div class="col-12 p-1" v-for="(checkpoint, index) in selectedCheckpoints" :key="index">
                        <div class="card p-2 text-center k4-selected" >
                            <div class="row align-items-center">
                                <div class="col-4">{{checkpointName(checkpoint.checkpointId)}}</div>
                                <div v-if="checkpoint.direction == 'IN'" class="col-1">{{$t("directionIN")}}</div>
                                <div v-else class="col-1">{{$t("directionOUT")}}</div>
                                <div class="col-3">{{$t("start")}} : {{getTimeFromLuxonDateTime(checkpoint.startDate)}}</div>
                                <div class="col-3">{{$t("end")}} : {{getTimeFromLuxonDateTime(checkpoint.endDate)}}</div>
                                <div v-if="index+1 == selectedCheckpoints.length" class="col-1" role="button" @click="removeLastCheckpoint"><font-awesome-icon :icon="['fas','minus-circle']" size="2x" class="k4-removeLast" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-12 p-1">
                        <div class="row">
                            <div class="col-5">
                                <select v-model="currentCheckpoint.checkpointId" class="k4-selectCheckpoint form-control">
                                    <option disabled value="0">{{ $t("pleaseSelect") }}</option>
                                    <option v-for="chk in checkpointsDiff" :key="chk.id" v-bind:value="chk.id">{{chk.name}}</option>
                                </select>
                            </div>
                            <div class="col-2">
                                <select v-model="currentCheckpoint.direction" class="k4-selectDirection form-control">
                                    <option value="IN">{{ $t("directionIN") }}</option>
                                    <option value="OUT">{{ $t("directionOUT") }}</option>
                                </select>
                            </div>
                            <div class="col-2">
                                <b-form-timepicker v-model="this.currentCheckpoint.startTime" hide-header :minutes-step="15" placeholder="" class="k4-startTimePicker"></b-form-timepicker>
                            </div>
                            <div class="col-2">
                                <b-form-timepicker v-model="this.currentCheckpoint.endTime" hide-header :minutes-step="15" placeholder="" class="k4-endTimePicker"></b-form-timepicker>
                            </div>
                            <div class="col-1"><b-button class="btn btn-primary" @click="addCheckpoint"><font-awesome-icon :icon="['fas','plus']" /></b-button></div>
                        </div>
                    </div>
                </div>
            </div>

            <template v-slot:modal-footer>
                <div class="d-flex align-items-center justify-content-between">
                    <ButtonExportPDF ref="ButtonExportPDF" @click.native="exportPDF"></ButtonExportPDF>
                    <div>
                        <button class="btn btn-outline-secondary mr-2" @click="modalShow = !modalShow">{{ $t("cancel")}}</button>
                        <button class="btn btn-danger mr-2" @click="askToDelete" >{{ $t("delete")}}</button>
                        <button class="btn btn-success" @click="updateUserCheckpoint">{{ $t("update")}}</button>
                    </div>
                </div>
            </template>
        </b-modal>

        <b-modal v-if="user" v-model="confirmModal.show" v-bind:title="$t('deleteUser')">
            {{ $t("comfirmDelete") }} {{user.name}} ?

            <template #modal-footer>
                <div class="w-100">
                    <button type="button" @click="confirmModal.show = false" class="btn btn-outline-secondary mx-1">{{$t("cancel")}}</button>
                    <button type="button" @click="deleteUser" class="btn btn-danger mx-1">{{$t('delete')}}</button>
                </div>
            </template>
        </b-modal>
        <GenerateRobotPDF ref="robotPdf" @onEndExportPDF = "onEndExportPDF" />
    </div>
</template>
<script>
    import CheckpointService from '../../services/checkpoint.service';
    import UserService from '../../services/user.service';
    import utilMixin from '../../mixins/utils.mixins';
    import RobotCheckpointModel from '../../models/robotCheckpointModel';
    import GenerateRobotPDF from '../pdf/GenerateRobotPDF.vue';
    import ButtonExportPDF from '../pdf/ButtonExportPDF.vue';
    import { DateTime } from "luxon";

    export default {
        name:"UpdatePinRobot",
        mixins: [utilMixin],
        components: {
            GenerateRobotPDF,
            ButtonExportPDF
        },
        props : ['event'],
        data: function(){
            return {
                modalShow:false,
                user:{},
                dateOfWork: new Date(),
                currentCheckpoint: this.getInitialRobotCheackpoint(),
                checkpoints:[],
                checkpointsDiff:[],
                selectedCheckpoints:[],
                confirmModal:{
                    show:false,
                },
                selectedValue: new Date()
            }
        },
        computed: {
            
        },
        watch:{
            user: function(val){
                if(val && val.eventId){
                    this.selectedCheckpoints=[];
                    this.refreshCheckpointList();
                }
            },
            modalShow: function(val){
                if(val == false){
                    this.user={};
                    this.currentCheckpoint = this.getInitialRobotCheackpoint();
                    this.selectedCheckpoints=[];
                }
            },
            dateOfWork(newValue) {
                // Set the all start and end dates to match the selected date of work
                this.selectedCheckpoints.forEach(checkpoint => 
                    {
                        const [dateOfWorkYear, dateOfWorkMonth, dateOfWorkDay] = newValue.split("-").map(Number);
                        checkpoint.startDate = DateTime.fromObject({
                            year: dateOfWorkYear,
                            month: dateOfWorkMonth,
                            day: dateOfWorkDay,
                            hour: checkpoint.startDate.hour,
                            minute: checkpoint.startDate.minute
                        }, { zone: this.event.timezoneIso });

                        checkpoint.endDate = DateTime.fromObject({
                            year: dateOfWorkYear,
                            month: dateOfWorkMonth,
                            day: dateOfWorkDay,
                            hour: checkpoint.endDate.hour,
                            minute: checkpoint.endDate.minute,
                        }, { zone: this.event.timezoneIso });
                    }
                );
            },
        },
        methods:{
            onEndExportPDF(){
                this.$refs.ButtonExportPDF.onEndExportPDF();
            },
            getInitialRobotCheackpoint(){
                return new RobotCheckpointModel(0,'IN','','');
            },
            getTimeFromLuxonDateTime(dateTime){
                return dateTime.toFormat("HH:mm 'UTC'ZZ");
            },
            addCheckpoint: function(){
                this.currentCheckpoint.startDate = DateTime.fromISO(this.dateOfWork + "T" + this.currentCheckpoint.startTime, { zone: this.event.timezoneIso });
                this.currentCheckpoint.endDate = DateTime.fromISO(this.dateOfWork + "T" + this.currentCheckpoint.endTime, { zone: this.event.timezoneIso });
                if(this.currentCheckpoint.checkpointId == 0){
                    this.showToast(this.$t('warning'), 'Please select a checkpoint !', 'warning' );
                    return;
                }
                else if(!this.currentCheckpoint.startDate || !this.currentCheckpoint.endTime){
                    this.showToast(this.$t('warning'), 'Start date and end date can\'t be empty !', 'warning' );
                    return;
                }
                else if(this.currentCheckpoint.startDate >= this.currentCheckpoint.endDate){
                    this.showToast(this.$t('warning'), 'Start date can\'t be equal or greater than end date !', 'warning' );
                    return;
                }
                else if(this.selectedCheckpoints.length > 0 && this.selectedCheckpoints[this.selectedCheckpoints.length-1].endDate === this.currentCheckpoint.startDate){
                    this.showToast(this.$t('warning'), 'Start date doesn\'t match with previous end date !', 'warning' );
                    return;
                }

                this.selectedCheckpoints.push(Object.assign({}, this.currentCheckpoint));
                this.currentCheckpoint = new RobotCheckpointModel(0,'IN','','');
                this.updateCurrentCheckpoint(this.selectedCheckpoints[this.selectedCheckpoints.length -1].endDate);
                this.checkpointDiff();
            },
            removeLastCheckpoint : function(){
                if(this.selectedCheckpoints){
                    this.selectedCheckpoints.splice(this.selectedCheckpoints.length-1, 1);
                    this.currentCheckpoint = this.getInitialRobotCheackpoint();
                    if(this.selectedCheckpoints.length > 0){
                        this.updateCurrentCheckpoint(this.selectedCheckpoints[this.selectedCheckpoints.length-1].endDate);
                    }
                    this.checkpointDiff();
                }
            },
            checkpointName: function(id){
                if(this.checkpoints && id !== undefined){return this.checkpoints.find((i) => i.id == id).name;}
                return '';
            },
            checkpointDiff: function(){
                // Reset + refilling
                this.checkpointsDiff = [];
                this.checkpoints.forEach(i => this.checkpointsDiff.push(i));

                // Updating the combo box
                this.selectedCheckpoints.forEach(element => {
                    if (element !== undefined) {
                        var result = this.checkpointName(element.checkpointId);
                        if (result != '') {
                            for(let i = 0; i < this.checkpointsDiff.length; i++) {
                                if (this.checkpointsDiff[i].id == element.checkpointId){
                                    this.checkpointsDiff.splice(i, 1); 
                                    i--; 
                                }
                            }
                        }
                    }
                });
            },
            refreshCheckpointList: function(){
                if(this.user && this.user.eventId){
                    CheckpointService.getAllCheckpoints(this.user.eventId).then(
                        resp => {
                            var response = resp.status ? resp : resp.response; 
                            if(response.status==200){
                                if(this.checkpoints.length == 0){
                                    this.checkpoints = response.data;
                                }
                                
                                CheckpointService.getRobotCheckpoint(this.user).then(
                                    resp => {
                                        var response = resp.status ? resp : resp.response; 
                                        if(response.status == 200){
                                            // clear selected checkpoints
                                            this.selectedCheckpoints=[];
                                            this.dateOfWork = DateTime.now().toFormat("yyyy-MM-dd");
                                            if (response.data.length != 0) {
                                                // startDateTimeLocal and endDateTimeLocal are given in local time and use the following format 2023-06-06T00:30:00
                                                console.log(response.data)
                                                response.data.forEach(i => this.selectedCheckpoints.push(
                                                    // Rebuild selected checkpoints to convert DateTime to Luxon objects
                                                    new RobotCheckpointModel(i.checkpointId, i.direction, DateTime.fromISO(i.startDateTimeLocal, { setZone: true }), DateTime.fromISO(i.endDateTimeLocal, { setZone: true }))
                                                ));
                                                // update date of work with first selected checkpoint
                                                this.dateOfWork = this.selectedCheckpoints[0].startDate.toFormat('yyyy-MM-dd');
                                                // current checkpoint depend of last selected checkpoints
                                                this.updateCurrentCheckpoint(this.selectedCheckpoints[this.selectedCheckpoints.length-1].endDate);
                                            }
                                            this.checkpointDiff();
                                        }
                                        else {
                                            this.showToast(this.$t('error'), response.data, 'danger');
                                        }
                                    }
                                )
                            }
                        }
                    );
                }
            },
            showModal: function(){
                this.modalShow=true;
            },
            updateUserCheckpoint : function(){
                var userCheckpoints = [];

                // Convert into RobotUserCheckpointModel backend DTO
                this.selectedCheckpoints.forEach(checkpoint => userCheckpoints.push(
                    {
                        userId: this.user.id, 
                        checkpointId: checkpoint.checkpointId, 
                        direction: checkpoint.direction, 
                        startDateTimeLocal: checkpoint.startDate.toISO(),
                        endDateTimeLocal: checkpoint.endDate.toISO()
                    }
                ));

                CheckpointService.putRobotCheckpoints(userCheckpoints, this.user.eventId).then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        if(response.status == 200) {
                            this.showToast(this.$t('success'), response.data, 'success')
                            this.modalShow=false;
                            this.$emit("action_ok");
                        } else {
                            
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                ).catch(error => {
                    this.showToast(this.$t('error'), error, 'danger');
                });
            },
            askToDelete:function(){
                this.confirmModal.show = true;
            },
            onDayClick: function(day){
                if(this.selectedCheckpoints.length > 0){
                    let ref = new Date(day.date);
                    this.selectedCheckpoints.forEach(i => 
                    {
                        let iStartRef = new Date(i.startDate);
                        i.startDate = new Date(ref.getFullYear(), ref.getMonth(), ref.getDate(), iStartRef.getHours(),iStartRef.getMinutes(),0,0);
                        let iEndRef = new Date(i.endDate);
                        i.endDate = new Date(ref.getFullYear(), ref.getMonth(), ref.getDate(), iEndRef.getHours(),iEndRef.getMinutes(),0,0);
                    });

                    this.updateCurrentCheckpoint(this.selectedCheckpoints[this.selectedCheckpoints.length-1].endDate);
                }
                else {
                    this.updateCurrentCheckpoint(new Date(this.dateOfWork.getFullYear(), this.dateOfWork.getMonth(), this.dateOfWork.getDate(),8,0,0,0));
                }
            },
            updateCurrentCheckpoint: function(lastCheckpointEndDate) { // lastCheckpointEndDate is a Luxon DateTime
                this.currentCheckpoint.startDate = lastCheckpointEndDate;
                this.currentCheckpoint.endDate = lastCheckpointEndDate.plus({minutes: 30});
                this.currentCheckpoint.startTime = this.currentCheckpoint.startDate.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
                this.currentCheckpoint.endTime = this.currentCheckpoint.endDate.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            },
            deleteUser: function(){
                if (this.user){
                    UserService.deleteUser(this.user).then(
                        resp => {
                            var response = resp.status ? resp : resp.response; 
                            if(response.status == 200){
                                this.showToast(this.$t('confirmedDeleteUser'), this.user.name + this.$t('hasBeenDeleted'));
                                this.$emit("delete_ok");
                                this.modalShow = false;
                                this.confirmModal.show = false;
                            }
                            else {
                                this.showToast(this.$t('error'), response.data, 'danger');
                            }
                        }
                    )
                }
            },
            exportPDF: function(){
                this.$refs.ButtonExportPDF.onStartExportPDF();

                this.$refs.robotPdf.users.push(this.user);
                this.$refs.robotPdf.checkpoints = this.checkpoints;
                this.$refs.robotPdf.createPDF();
            }
        }
    }
</script>

<style scoped>
    .k4-modal__body {
        min-height: 50vh;
    }
    .k4-removeLast {
        color: var(--red);
        background-color: var(--white);
        border-radius: 50%;
    }
    .k4-removeLast:hover {
        color: var(--danger);
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }
    .k4-selectCheckpoint, .k4-selectDirection, .k4-startTimePicker, .k4-endTimePicker {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }
</style>