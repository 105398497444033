<template>
    <div class="row h-100">
        <div v-if="!isSmallScreen" class="col-md-6 k4-logoContainer d-flex flex-column align-items-end flex-column">
            <div class="my-auto">
                <div>
                    <img src="../assets/key4access_logo_image_white_background.png" class="k4-logoContainer__logoImage mx-auto d-block"/>
                </div>
                <div class="my-4">
                    <img src="../assets/key4access_logo_text_white.png" class="k4-logoContainer__logoText mx-auto d-block"/>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 bg-white d-flex align-items-center">
            <div class="k4-loginContainer mx-auto">
                <div class="d-flex justify-content-end">
                    <LanguageSelector backgroundColorClass="bg-secondary" />
                </div>
                <p class="k4-loginContainer__welcome text-center"><span class="text-capitalize">{{$t("welcome")}}</span> {{$t("on")}} <span class="k4-loginContainer__welcome__brandName font-weight-bold">KEY4ACCESS</span></p>
                <form name="form" @submit.prevent="handleLogin" class="mt-3 bg-white">
                    <div class="form-group">
                        <div class="input-group input-group-lg border" style="1px solid black !important">
                            <input type="text" name="username" class="form-control" v-model="user.username" v-bind:placeholder="$t('identification')" >
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group input-group-lg ">
                            <input :type="isShowPassword ? 'text' : 'password'" name="password" class="form-control border border-right-0" v-model="user.password" v-bind:placeholder="$t('password')">
                            <span class="input-group-append border border-left-0">
                                <span class="input-group-text bg-transparent">
                                    <i @click="setIsShowPassword(!isShowPassword)"><font-awesome-icon :icon="isShowPassword ? 'eye-slash' : 'eye'" role="button" style="color: black !important;"/></i>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="row my-5">
                        <div class="col-12 order-1 order-md-12 mb-3 text-center text-md-right">
                            <button class="btn btn-success col-12">
                                <span v-show="loading" class="spinner-border spinner-border-sm mr-3"></span>
                                <span>{{ $t("login") }}</span>
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <div v-if="message" class="alert alert-danger">{{message}}</div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import LanguageSelector from '../components/users/LanguageSelector.vue';
    import User from '../models/user';
    import { mapState } from 'vuex';

    export default {
        name: 'LoginView',
        components: { LanguageSelector },
        data() {
            return {
                user : new User('',''),
                loading: false,
                message: '',
                isShowPassword: false
            };
        },
        computed: {
            ...mapState(["isSmallScreen"]),
            loggedIn() {
                return this.$store.state.auth.status.loggedIn;
            }
        },
        mounted: function () {
            document.body.classList.add("login");
        },
        beforeUnmount: function () {
            document.body.classList.remove("login");
        },
        methods: {
            setIsShowPassword(value) {
                this.isShowPassword = value;
            },
            handleLogin: function() {
                this.loading = true;

                if(this.user.username && this.user.password){
                    this.$store.dispatch('auth/login', this.user).then(
                        () => {
                            this.$router.replace({ name : 'Root'});
                        },
                        () => {
                            this.loading = false;
                            this.message = this.$t("invalidLogin");
                        }
                    );
                }
                else{
                    this.loading = false;
                    this.message = this.$t("invalidLogin");
                }
            }
        }    
    }
</script>

<style scoped>
    .k4-modal{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 50%);
    }

    .k4-login{
        width: 400px;
        margin: 150px auto 0 auto;
        padding: 30px 20px 10px;
    }

    .k4-logoContainer {
        background: var(--primary);
        background: radial-gradient(circle, var(--primary) 0%, var(--primary-dark) 70%);
    }
    .k4-logoContainer__logoImage {
        height: auto; 
        max-width: 25%;
    }
    .k4-logoContainer__logoText {
        height: auto; 
        max-width: 40%;
    }

    .k4-loginContainer {
        width: 70%;
    }
    .k4-loginContainer__welcome {
        font-size: 2em;
    }
    .k4-loginContainer__welcome__brandName {
        color: var(--success);
    }
</style>