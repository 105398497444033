<template>
    <div class="d-flex flex-column justify-content-center align-items-center">
        <font-awesome-icon icon="info-circle" class="fa-5x" style="opacity: 0.7;"/>
        <div class="k4-label font-weight-bold">{{ $t("noDataToDisplay") }}</div>
    </div>
</template>
  
<script>
    export default {
        data() {
            return {
            };
        },
        methods: {
        },
    };
</script>
  
  <style scoped>
    /* Component-specific styles go here */
    .k4-label {
        font-size: x-large;
        opacity: 0.7;
    }
  </style>