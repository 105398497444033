<template>
    <ul class="my-auto user-infos list-unstyled p-0 m-0 pointer-click" v-on:click="openUserMenu">
        <li class="my-auto profile">
            <span class="my-auto d-none d-lg-inline-block no-ul" v-bind:title='$t("profile")'>
                <div class="d-flex align-items-center text-white">
                    <font-awesome-icon icon="circle-user" class="mx-2 k4-user__icon" style="font-size: 2em;"/>
                    <span class="d-inline-block align-middle mr-1 text-truncate k4-user__name" style="font-size:1.1em;">{{ userName }}</span>
                    <font-awesome-icon icon="angle-down" size="1x" />
                </div>
            </span>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'AuthenticationUsers',
    data:function() {return {
        modalShow:false
    }},
    methods:{ 
        openUserMenu: function () {
            this.$emit('toggle');
        }
    },
    computed: {
        userName: function(){
            return this.$store.state.auth.user ? this.$store.state.auth.user.name : null;
        },
        roles : function(){
            return this.$store.state.auth.user ? this.$store.state.auth.user.roles : null;
        }
    }
}
</script>

<style>
    .k4-user__icon {
        font-size: 2em;
    }
    .k4-user__name {
        font-size: 1.1em;
    }
</style>