import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL_BASE + 'api/admin/statistics/[eventId]/';

class StatisticsService {
    getStatistics(eventId){
        axios.res
        return axios.get(API_URL.replace('[eventId]', eventId), { transformResponse: (r) => r, headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getRushHourStatistics(eventId, checkpoints){     
        axios.res
        return axios.put(API_URL.replace('[eventId]', eventId) + "rushhour", checkpoints, { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }
}

export default new StatisticsService;