<template>
    <section class="mt-5" :id="id">
        <div class="py-2 mb-2 w-100">
            <i class="position-absolute h3 text-primary-dark">
                <font-awesome-icon v-bind:icon="icon" />
            </i>
            <h3 class="k4-global-sectionTitle d-inline-block pl-5">{{ title }}</h3>
        </div>
        <div class="k4-slot" :style="this.heightStyle">
            <slot></slot>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'SectionComponent',
        props: {
            title: String,
            icon: String
        },
        data() {
            return {
                height: 0
            }
        },
        computed: {
            heightStyle() {
                if(this.height && this.height > 0){
                    return "height: " + this.height + "px !important";
                }
                else {
                    return "";
                }
            }
        },
        methods: {
            updateHeight(newHeight) {
                this.height = newHeight;
            }
        }
    }
</script>

<style scoped>

</style>