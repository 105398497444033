import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL_BASE + 'api/admin/event/[eventId]/checkentries';

class CheckEntriesService{

    getByCheckpoint(model, eventId, checkpointId){
        if(!eventId){ throw new Error('Error: eventId is null.'); }
        if(!checkpointId){ throw new Error('Error: checkpointId is null.'); }

        var url = API_URL.replace('[eventId]', eventId) + '/checkpoint/' + checkpointId + '/page';
        return axios.put(url, model,{ headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getByBarcode(eventId, barcode){
        if(!eventId){ throw new Error('Error: eventId is null.'); }
        if(!barcode){ throw new Error('Error: barcode is null.'); }

        var url = API_URL.replace('[eventId]', eventId) + '/barcode/' + barcode;
        return axios.get(url, { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }
}
export default new CheckEntriesService;