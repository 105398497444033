import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView'
import DashboardView from '../views/DashboardView'
import EventsView from '../views/EventsView'
import EventView from '../views/EventView'
import StatisticsView from '../views/StatisticsView'
import UsersView from '../views/UsersView'
import BackgroundTasksView from '../views/BackgroundTasksView'
import { useHead } from 'unhead'

const routes = [
  {
    path : '/',
    name : 'Root',
    component: EventsView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:'/dashboard',
    name: 'Dashboard',
    component: DashboardView
  },
  {
    path:'/events',
    name: 'Events',
    component: EventsView
  },
  {
    path:'/events/:id',
    name: 'Event',
    component: EventView
  },
  {
    path:'/events/:id/statistics',
    name: 'Statistics',
    component: StatisticsView
  },
  {
    path:'/users',
    name: 'Users',
    component: UsersView
  },
  {
    path:'/backgroundTasks',
    name: 'BackgroundTasks',
    component: BackgroundTasksView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})



router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  useHead({
      title: to.name + " | K4access"
  })

  window.scrollTo(0, 0); // Scroll to the top of the page every time a new page is loaded  

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } 
  else if (to.path == '/' || to.path == '/home'){
    next('/events');
  } 
  else {
    next();
  }
});

export default router
