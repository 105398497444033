<template>
    <div class="k4-page">
        <section class="row align-items-center">
            <div class="col-sm-12 col-md-6">
                <h2 class="k4-global-pageTitle mb-0 title">{{ $t('authorizeUsers') }}</h2>
            </div>

            <div class="col-sm-12 col-md-6 d-flex align-items-center">
                <form class="my-auto align-items-center flex-fill">
                    <div class="input-group input-group-lg input-group-icon icon-right">
                        <input id="searchInput" type="text" class="form-control" v-bind:placeholder="$t('search')" v-model="searchQuery" v-on:keyup="filterUsersList">
                        <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon2">
                                <font-awesome-icon icon="search" />
                            </span>
                        </div>
                    </div>
                </form>

                <AddUser class="mx-3" @create_ok="refreshUsersList" />
            </div>
        </section>

        <div v-if="this.filteredList.length > 0">
            <div class="row mt-5">
                <div class="col-xl-3 col-lg-6 pb-3" v-for="user in filteredList" v-bind:key="user.id">
                    <div class="card shadow-sm k4-cards">
                        <div class="card-body">
                            <div class="float-right">
                                <div class="p-1 k4-pointer k4-user__icon" v-on:click="updateUser(user)"><font-awesome-icon :icon="['fas','pen']" v-bind:title="$t('edit')" /></div>
                                <div class="p-1 k4-pointer k4-user__icon" v-on:click="deleteUser(user)"><font-awesome-icon :icon="['fas','trash-alt']" v-bind:title="$t('delete')" /></div>
                            </div>
                            <p class="fw-bold k4-user__name">{{user.name}}</p>
                            <span class="badge badge-primary mr-2 my-1" v-for="(right, index) in user.roles" v-bind:key="index">
                                #{{right.name}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <UpdateUser ref="myUpdateUser" @update_ok="refreshUsersList" />
            <DeleteConfirmUser ref="myConfirmDeleteUser" @update_ok="refreshUsersList" />
        </div>
        <div v-else class="d-flex justify-content-center align-items-center h-100">
            <NoData></NoData>
        </div>
    </div>
</template>

<script>
    import UserService from '../services/user.service';
    import AddUser from '../components/users/AddApplicationUser'; 
    import UpdateUser from '../components/users/UpdateUser';
    import DeleteConfirmUser from '../components/users/DeleteConfirmUser';
    import utilMixin from '../mixins/utils.mixins';
    import NoData from '../components/NoData';

    export default {
        name:"UsersView",
        mixins: [utilMixin],
        data: function(){
            return {
                searchQuery: '',
                users : [], 
                filteredList: [],
                updateShowModal : false,
                updateShowModalUser:{}
            }
        },
        created: function(){
            this.refreshUsersList();
        },
        methods: {
            refreshUsersList: function(){
                UserService.getAllUsers().then(lst =>{
                    this.users = lst.data;
                    this.filteredList = lst.data;
                    this.searchQuery='';
                });
            },
            filterUsersList: function(){
                this.filteredList = this.users.filter(item => {
                    return (item.name.toLowerCase() + ' ' + item.roles.toString().toLowerCase()).includes(this.searchQuery.toLowerCase())
                });
            },
            deleteUser: function(user){
                this.$refs.myConfirmDeleteUser.user = JSON.parse(JSON.stringify(user));
                this.$refs.myConfirmDeleteUser.showModal();
            },
            updateUser: function(user){
                this.$refs.myUpdateUser.user=JSON.parse(JSON.stringify(user));
                this.$refs.myUpdateUser.showModal();
            }
        },
        components : { AddUser, UpdateUser, DeleteConfirmUser, NoData }
    }
</script>

<style scoped>
    .k4-user__name, .k4-user__icon {
        font-size: 1.2em;
    }
</style>