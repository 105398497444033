<template>
    <button :disabled="isDownloadingPDF" class="btn btn-pdf d-flex align-items-center justify-content-center">
        <span v-if="isDownloadingPDF" class="spinner-grow spinner-grow-sm mx-2" role="status">
            <span class="sr-only"></span>
        </span>
        <font-awesome-icon icon="file-pdf" size="1x" />{{ $t('export') }}
    </button>
</template>

<script>
    import utilMixin from '../../mixins/utils.mixins';

    export default {
        name : 'ButtonExportPDF',
        mixins: [utilMixin],
        data() {
            return {
                isDownloadingPDF: false
            }
        },
        methods: {
            onStartExportPDF(){
                this.isDownloadingPDF = true;
                this.showToast(this.$t("info"), this.$t("startExportPDF"));
            },
            onEndExportPDF() {
                setTimeout(() => {
                    this.isDownloadingPDF = false;
                    this.showToast(this.$t("success"), this.$t("endExportPDF"), "success");
                }, "5000");
            },
        }
    }
</script>