import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL_BASE + 'api/admin/backgroundTask/';

class BackgroundTaskService{
    getBackgroundTasks(){
        return axios.get(API_URL, {headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    startJob(jobId){
        var url = API_URL + jobId + '/start'; 
        return axios.get(url, {headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    stopJob(jobId){
        var url = API_URL + jobId + '/stop'; 
        return axios.get(url, {headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getHistory(numberOfLines){
        var url = API_URL + 'history' + (numberOfLines ? ('/' + numberOfLines) : '');
        return axios.get(url, {headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }
}

export default new BackgroundTaskService;