import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL_BASE + 'api/admin/users/';
const API_EVENT_URL = process.env.VUE_APP_API_URL_BASE + 'api/admin/events/[eventId]/users/';
const API_ROLE_URL = process.env.VUE_APP_API_URL_BASE + 'api/admin/roles/';

class UserService{
    getAllGroups(){
        return axios.get(API_URL + "groups/all",{ headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getAllUsers(){
        return axios.get(API_URL + "all",{ headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getUsersByEvent(eventId){
        return axios.get(API_EVENT_URL.replace("[eventId]",eventId), { headers: authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    putUser(user){
        return axios.put(API_URL, user, {headers : authHeader()}).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    putPinUser(user){
        return axios.put(API_EVENT_URL.replace("[eventId]",user.EventId), user, {  headers : authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    updateUserRoles(user){
        return axios.put(API_URL + user.id + "/roles/", {UserId : user.id, UserRoles : user.roles },{  headers : authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    assignUserGroup(userId, userGroupLabel) {
        return axios.put(API_URL + "groups/assign/", { UserId: userId, UserGroupLabel: userGroupLabel }, { headers : authHeader() }).then(
            response => { return response; },
            error => { return Promise.reject(error); }
        );
    }

    updateUserEvents(user){
        return axios.put(API_URL + user.id + "/events/", {UserId : user.id, UserEvents : user.events},{  headers : authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    deleteUser(user){
        return axios.delete(API_URL + user.id, {headers : authHeader()}).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    getAllRoles(filter){
        return axios.get(API_ROLE_URL + (filter ? "?filter=" + filter : ""), {headers : authHeader() }).then(
            response => {return response;},
            error => {return Promise.reject(error);}
        );
    }

    isSuperAdmin(){
        let user = JSON.parse(localStorage.getItem('user'));
        return user ? user.roles.includes("key4admin") : false;
    }

    isAdmin(){
        let user = JSON.parse(localStorage.getItem('user'));
        return user ? ( this.isSuperAdmin() ? true : user.roles.includes("admin")) : false;
    }

    getUserId(){
        let user = JSON.parse(localStorage.getItem('user'));
        return user.userID;
    }
}

export default new UserService();