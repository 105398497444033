<template>
        <div class="row">
            <div class="col-sm-4">
                <div class="card shadow k4-stat">
                    <div class="card-body">
                        <div v-if="firstLoading" class="v-spinner" id="loader-card" v-show="loading">
                            <div class="v-beat v-beat-odd" v-bind:style="spinnerStyle">
                                </div><div class="v-beat v-beat-even" v-bind:style="spinnerStyle">
                                </div><div class="v-beat v-beat-odd" v-bind:style="spinnerStyle">
                            </div>
                        </div>
                        <div v-else class="d-flex align-items-center">
                            <div class="k4-statBloc__iconContainer bg-primary d-flex justify-content-center align-items-center mr-3">
                                <font-awesome-icon icon="location-dot" class="k4-statBloc__iconContainer__icon text-white" />
                            </div>
                            <div class="k4-statBloc__number text-primary text-bold mr-1">{{numberOfCheckpoints}}</div>
                            <div class="k4-statBloc__label text-primary-dark">{{ $t("countCheckpoints") }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="card shadow k4-stat">
                    <div class="card-body">
                        <div v-if="firstLoading" class="v-spinner" id="loader-card" v-show="loading">
                            <div class="v-beat v-beat-odd" v-bind:style="spinnerStyle">
                                </div><div class="v-beat v-beat-even" v-bind:style="spinnerStyle">
                                </div><div class="v-beat v-beat-odd" v-bind:style="spinnerStyle">
                            </div>
                        </div>
                        <div v-else class="d-flex align-items-center">
                            <div class="k4-statBloc__iconContainer bg-primary d-flex justify-content-center align-items-center mr-3">
                                <font-awesome-icon icon="id-badge" class="k4-statBloc__iconContainer__icon text-white" />
                            </div>
                            <div class="k4-statBloc__number text-primary text-bold mr-1">{{numberOfKnowBarcodes}}</div>
                            <div class="k4-statBloc__label text-primary-dark">{{ $t("countRegistered") }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="card shadow k4-stat">
                    <div class="card-body">
                        <div v-if="firstLoading" class="v-spinner" id="loader-card" v-show="loading">
                            <div class="v-beat v-beat-odd" v-bind:style="spinnerStyle">
                                </div><div class="v-beat v-beat-even" v-bind:style="spinnerStyle">
                                </div><div class="v-beat v-beat-odd" v-bind:style="spinnerStyle">
                            </div>
                        </div>
                        <div v-else class="d-flex align-items-center">
                            <div class="k4-statBloc__iconContainer bg-primary d-flex justify-content-center align-items-center mr-3">
                                <font-awesome-icon icon="user-check" class="k4-statBloc__iconContainer__icon text-white" />
                            </div>
                            <div class="k4-statBloc__number text-primary text-bold mr-1">{{numberOfCheckedIn}}</div>
                            <div class="k4-statBloc__label text-primary-dark">{{ $t("countPresent") }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import EventService from '../../services/event.service';
import utilMixin from '../../mixins/utils.mixins';

export default {
    name : "StatEvent",
    mixins: [utilMixin],
    data : function(){
        return {
            timer : '',
            numberOfCheckpoints : 0, 
            numberOfKnowBarcodes : 0,
            numberOfCheckedIn : 0,
            firstLoading : false,
            spinnerStyle: {
                backgroundColor: this.color,
                height: this.size,
                width: this.size,
                margin: this.margin,
                borderRadius: this.radius
            }
        }
    },
    props:{
        event: {
            type: Number,
            default: 0
        },
        loading: {
            type: Boolean,
            default: true
        },
        color: { 
            type: String,
            default: '#3286EF'
        },
        size: {
            type: String,
            default: '15px'
        },
        margin: {
            type: String,
            default: '3px'
        },
        radius: {
            type: String,
            default: '100%'
        }
    },
    created : function(){
        // this.timer = setInterval(this.refreshStats(),3000);
        this.timer = setInterval(() => {this.refreshStats(); },15000);
    },
    beforeUnmount : function(){
        clearInterval(this.timer);
    },
    watch : {
        event : function(val){
            if(val){
                this.refreshStats();
            }
        }
    },
    methods : {
        refreshStats : function() {
            if(this.event){
                EventService.getEventStats(this.event.id).then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        if(response.status == 200){
                            this.numberOfCheckpoints = response.data.numberOfCheckpoints;
                            this.numberOfKnowBarcodes = response.data.numberOfKnowBarcodes;
                            this.numberOfCheckedIn = response.data.numberOfCheckedIn;

                            if (this.firstLoading){
                                this.firstLoading = false;
                            }
                        }
                        else{
                            
                            this.showToast(this.$t("error"), response.data, 'danger');
                        }
                    }
                );
            }
        }
    }
}
</script>

<style scoped>
    .k4-statBloc__iconContainer {
        width: 48px; 
        height: 48px; 
        border-radius: 50%;
    }
    .k4-statBloc__iconContainer__icon {
        font-size: 24px;
    }
    .k4-statBloc__number {
        font-size: 32px;
    }
    .k4-statBloc__label {
        font-size: 16px;
    }

    .k4-stat {
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px !important;
        color: var(--main-top-font-color); 
        padding: 0 0 5px 5%; 
        border:none; 
        margin-top: 30px;
    }
    .k4-stat .number{font-size: 32px;}

    #loader-card{
        height: 48px !important;
    }

    .v-spinner .v-beat{
        -webkit-animation: v-beatStretchDelay 0.7s infinite linear;
                animation: v-beatStretchDelay 0.7s infinite linear;
        -webkit-animation-fill-mode: both;
                animation-fill-mode: both;
        display: inline-block;
        vertical-align: center;
    }

    .v-spinner .v-beat-odd{
        animation-delay: 0s;
        margin-top: 10px !important;
    }
    .v-spinner .v-beat-even{
        animation-delay: 0.35s;
        margin-top: 10px !important;
    }

    @-webkit-keyframes v-beatStretchDelay{
        50%{
            -webkit-transform: scale(0.75);
                    transform: scale(0.75);
            -webkit-opacity: 0.2;             
                    opacity: 0.2;
        }
        100%{
            -webkit-transform: scale(1);
                    transform: scale(1);
            -webkit-opacity: 1;             
                    opacity: 1;
        }
    }

    @keyframes v-beatStretchDelay{
        50%{
            -webkit-transform: scale(0.75);
                    transform: scale(0.75);
            -webkit-opacity: 0.2;             
                    opacity: 0.2;
        }
        100%{
            -webkit-transform: scale(1);
                    transform: scale(1);
            -webkit-opacity: 1;             
                    opacity: 1;
        }
    }
</style>