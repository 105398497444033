<template>
    <nav class="k4-navigationBar menu-left" :class="isMenuDrawerOpened ? 'k4-navigationBar--opened' : 'k4-navigationBar--closed'">
        <ul class="m-0 list-unstyled h-100 w-100 d-flex align-items-center">
            <li v-for="(menu, index) in menus" v-bind:key="index" v-bind:title="menu.text" v-on:click="goTo(menu)" class="d-flex align-items-center mx-2 position-relative">
                <div v-bind:class="menuClass(menu)" class="k4-navigationBar__menuItemIcon d-flex justify-content-center align-items-center" role="button">
                    <font-awesome-icon v-bind:icon="menu.icon" class="k4-navigationBar__menuItem__icon mx-2"/>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script>
    import UserService from '../../services/user.service'
    export default {
        name:"MainMenuSmall",
        props: {
            value: Boolean
        },
        data : function() {
            return {
                menuReduced: true,
                isAdmin : false,
                isK4Admin : false
            };
        },
        created : function(){
                this.isAdmin = UserService.isAdmin() ?? false ;
                this.isK4Admin = UserService.isSuperAdmin() ?? false;
        },
        mounted: function() {
            document.body.classList.add("reduced-menu-left");
            document.body.classList.add("esp-cl");
            document.body.classList.remove("erp");
        },
        methods: {
            toggleMenu: function () {
                this.menuReduced = !this.menuReduced;
                if (!this.menuReduced) {
                    document.body.classList.remove("reduced-menu-left");
                } else {
                    document.body.classList.add("reduced-menu-left");
                }
            },
            goTo: function (menu) {
                if (!this.isActive(menu))
                    this.$router.replace({ name: menu.ref });
            },
            isActive: function(menu) {
                return this.$route.name == menu.ref;
            },
            menuClass: function (menu) {
                let classes = [];
                if (this.isActive(menu)) {
                    classes = ['bg-success', 'text-white'];
                } 
                else {
                    classes = ['bg-white', 'text-primary-dark'];
                }

                if(this.isMenuDrawerOpened && this.isActive(menu)){
                    classes.push('k4-navigationBar__menuItemLabel--opened--hover');
                }

                return classes;
            }
        },
        computed: {
            menus: function() {
                let result = [{ref: "Events", icon:"calendar", text: this.$t("events")}];
                if(this.isAdmin) {
                    result.push({ref: "Users", icon:"users", text: this.$t("menuUser")});
                }
                if(this.isK4Admin) {
                    result.push({ref: "BackgroundTasks", icon:"tasks", text: this.$t("menuTasks")});
                }
                return result;
            },
            isMenuDrawerOpened: {
                get() {
                    return this.value;
                }
            }
        }
    }
</script>

<style scoped>
    .k4-key4accessLogoContainer {
        width: 70px; 
        border-right: 2px solid var(--gray-light);
    }
    .k4-key4accessLogoContainer__image {
        width: 1.5em;
        height: auto;
    }
    .k4-navigationBar__menuItemIcon {
        width: 50px; 
        height: 50px; 
        border-radius: 10%;
    }
    .k4-navigationBar__menuItem__icon {
        font-size: 1.4em;
    }
</style>