<template>
    <nav class="k4-navigationBar menu-left" :class="isMenuDrawerOpened ? 'k4-navigationBar--opened' : 'k4-navigationBar--closed'">
        <div class="d-flex justify-content-center w-100 h-100">
            <div v-if="isMenuDrawerOpened" class="k4-key4accessLogoContainer d-flex justify-content-center align-items-start h-100">
              <img class="k4-key4accessLogoContainer__image py-5" src="../assets/key4access_logo_text_blue_vertical.png"/>
            </div>

            <ul v-if="isMenuDrawerOpened" class="list-unstyled p-3">
                <li v-for="(menu, index) in menus" v-bind:key="index" v-bind:title="menu.text" v-on:click="goTo(menu)" class="d-flex my-3">
                    <div v-bind:class="menuClass(menu)" class="k4-navigationBar__menuItem--opened d-flex justify-content-start align-items-center w-100 p-3" role="button">
                        <font-awesome-icon v-bind:icon="menu.icon" class="k4-navigationBar__menuItem__icon mx-2"/> <span class="my-auto text-left">{{ menu.text }}</span>
                    </div>
                </li>
            </ul>
            <ul v-else class="list-unstyled py-3 w-100 d-flex flex-column align-items-center">
                <li v-for="(menu, index) in menus" v-bind:key="index" v-bind:title="menu.text" v-on:click="goTo(menu)" class="d-flex align-items-center my-3 position-relative">
                    <div v-bind:class="menuClass(menu)" class="k4-navigationBar__menuItemIcon d-flex justify-content-center align-items-center" role="button">
                        <font-awesome-icon v-bind:icon="menu.icon" class="k4-navigationBar__menuItem__icon mx-2"/>
                    </div>
                    <div class="k4-navigationBar__menuItemLabel px-2" :class="menuClass(menu)"><span class="my-auto">{{ menu.text }}</span></div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import UserService from '../services/user.service'
    export default {
        name:"MainMenu",
        props: {
            value: Boolean
        },
        data : function() {
            return {
                menuReduced: true,
                isAdmin : false,
                isK4Admin : false
            };
        },
        created : function(){
                this.isAdmin = UserService.isAdmin() ?? false ;
                this.isK4Admin = UserService.isSuperAdmin() ?? false;
        },
        mounted: function() {
            document.body.classList.add("reduced-menu-left");
            document.body.classList.add("esp-cl");
            document.body.classList.remove("erp");
        },
        methods: {
            toggleMenu: function () {
                this.menuReduced = !this.menuReduced;
                if (!this.menuReduced) {
                    document.body.classList.remove("reduced-menu-left");
                } else {
                    document.body.classList.add("reduced-menu-left");
                }
            },
            goTo: function (menu) {
                if (!this.isActive(menu))
                    this.$router.replace({ name: menu.ref });
            },
            isActive: function(menu) {
                return this.$route.name == menu.ref;
            },
            menuClass: function (menu) {
                let classes = [];
                if (this.isActive(menu)) {
                    classes = ['bg-success', 'text-white'];
                } 
                else {
                    classes = ['bg-white', 'text-primary-dark'];
                }

                if(this.isMenuDrawerOpened && this.isActive(menu)){
                    classes.push('k4-navigationBar__menuItemLabel--opened--hover');
                }

                return classes;
            }
        },
        computed: {
            menus: function() {
                let result = [{ref: "Events", icon:"calendar", text: this.$t("events")}];
                if(this.isAdmin) {
                    result.push({ref: "Users", icon:"users", text: this.$t("menuUser")});
                }
                if(this.isK4Admin) {
                    result.push({ref: "BackgroundTasks", icon:"tasks", text: this.$t("menuTasks")});
                }
                return result;
            },
            isMenuDrawerOpened: {
                get() {
                    return this.value;
                }
            }
        }
    }
</script>

<style scoped>
    .k4-key4accessLogoContainer {
        width: 70px; 
        border-right: 2px solid var(--gray-light);
    }
    .k4-key4accessLogoContainer__image {
        width: 1.5em;
        height: auto;
    }
    .k4-navigationBar {
        background-color: #FFF !important; 
    }
    .k4-navigationBar--opened {
        flex: 0 0 300px !important;
    }
    .k4-navigationBar--closed {
        flex: 0 0 98px !important;
        width: 98px;
    }
    .k4-navigationBar__menuItemIcon {
        width: 50px; 
        height: 50px; 
        border-radius: 10%;
    }
    .k4-navigationBar__menuItemLabel {
        height: 50px;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 5%;
        border-bottom-right-radius: 5%;
        position: absolute;
        visibility: hidden;
        width: 0px;
    }
    .k4-navigationBar__menuItem__icon {
        font-size: 1.4em;
    }
    .k4-navigationBar__menuItemIcon:hover {
        border-top-right-radius: 0%;
        border-bottom-right-radius: 0%;
    }
    .k4-navigationBar__menuItemIcon:hover + .k4-navigationBar__menuItemLabel, .k4-navigationBar__menuItemLabel:hover {
        display: flex;
        justify-content: center;
        align-items: center;
        left: 50px;
        visibility: visible;
        transition: all 0.1s linear;
        width: 150px !important;
        background-color: #F1F1F1;
        color: #000;
        white-space: normal;
    }

    .k4-navigationBar__menuItemLabel:hover ~ .k4-navigationBar__menuItemIcon {
        border-top-right-radius: 0%;
        border-bottom-right-radius: 0%;
    }

    .k4-navigationBar__menuItem--opened {
        border-radius: 5px;
    }
    .k4-navigationBar__menuItem--opened:hover {
        color: var(--success) !important;
    }
    .k4-navigationBar__menuItemLabel--opened--hover:hover {
        color: var(--white) !important;
    }
</style>