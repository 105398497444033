<template>
    <div v-if="pdfCreate">
        <VueHtml2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="775px"
            :html-to-pdf-options="htmlToPdfOptions"
            ref="html2Pdf"

            @startPagination="onStartPagination"
            @progress="onProgress"
         >
         <template v-slot:pdf-content>
            <img src="../../assets/key4access_logo_text_blue.png" class="k4-global-PdfHeaderLogo mx-auto d-block m-2">
            
            <section class="pdf-item" v-for="(item,index) in pdfData" :key="index">
                 <div v-if="event.bannerPDF64 != ''" class="card shadow" style="padding-left: 25px">
                     <div class="card-body">
                         <div class="container">
                             <div class="row">
                                 <img :src="event.bannerPDF64" width="725" height="150">
                             </div>
                         </div>
                     </div>
                 </div>
                 <div class="card shadow" style="padding-left: 25px">
                     <div class="card-body">
                         <div class="container">
                             <div class="row">
                                 <h1>{{item.user.name}} (Robot)</h1>
                             </div>
                             <div class="position-absolute" style="right: 0px; padding-top: 25px">
                                 <Qrcode v-if="item.user.pin" :text="item.user.pin" :size="115" color="#2352a4"></Qrcode>
                             </div>
                             <div class="row pb-2" style="padding-top: 25px">
                                 <div class="col-lg-8">{{ $t("name") }}: {{item.user.name}}</div>
                             </div>
                             <div class="row pb-2" style="padding-top: 10px">
                                 <div class="col-lg-8">{{ $t("pinCode") }}: {{item.user.pin}}</div>
                             </div>
                             <div class="row pb-2" style="padding-top: 10px">
                                 <div class="col-lg-8">{{ $t("dayOfWork") }}: {{getDate(item.dateOfWork)}}</div>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div class="card shadow" style="padding-left: 25px">
                     <div class="card-body">
                         <div class="container">
                             <div class="row">
                                 <h3 class="py-2 mb-2 w-100">
                                     <i class="position-absolute">
                                         <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="robot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-robot fa-w-20"><path fill="currentColor" d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z" class=""></path></svg>                                </i>
                                     <span class="d-inline-block pl-5 w-75">{{ $t("checkpoints")}}</span>
                                 </h3>
                             </div>
                             <div class="row">
                                 <div class="col-12" v-if="item.selectedCheckpoints.length==0">
                                     <div class="alert alert-warning">{{ $t("checkpointlistEmpty") }}</div> 
                                 </div>
                                 <div class="col-12" v-else v-for="chpt in item.selectedCheckpoints" :key="chpt.checkpointId">
                                     <div class="card text-center k4-selected k4-robot-pdf" >
                                         <hr style="height:1px;border-top:1px solid #2352a4" />
                                         <div class="row">
                                             <div class="col-6">{{checkpointName(chpt.checkpointId)}}</div>
                                             <div class="col-2">{{chpt.direction}}</div>
                                             <div class="col-2">{{getTime(chpt.startDate)}}</div>
                                             <div class="col-2">{{getTime(chpt.endDate)}}</div>
                                         </div>
                                     </div>
                                 </div>
                             </div>               
                         </div>
                     </div>
                 </div>
                 <div v-if="key+1 != pdfData.length" class="html2pdf__page-break"/>
             </section>
         </template>
        </VueHtml2pdf>
    </div>
 </template>
 
 <script>
    import Qrcode from 'vue-qrcode-component';
    import VueHtml2pdf from 'vue-html2pdf'
    import EventService from '../../services/event.service';
    import CheckpointService from '../../services/checkpoint.service';
    import RobotCheckpointModel from '../../models/robotCheckpointModel';
    import utilMixin from '../../mixins/utils.mixins';
    import { DateTime } from "luxon";
    
    export default {
        name: "generatePDF",
        mixins: [utilMixin],
        components: {
            VueHtml2pdf, 
            Qrcode
        },
        data : function (){
            return {
                event : {},
                pdfData:[],
                users: [],
                checkpoints:[],
                currentCheckpoint: new RobotCheckpointModel(0,'IN','',''),
                bannerImage : "",
                pdfCreate : false,
                htmlToPdfOptions: {
                    margin: [10, 0, 10, 0],
                    filename: 'KEY4ACC_Robots',
                    image: {type: 'jpeg',quality: 0.98},
                    html2canvas: {
                        scale: 2,
                        bottom: 20
                    },
                    pagebreak: { mode: 'legacy'}, // css, legacy...
                    jsPDF: {
                        unit: 'mm',
                        orientation: 'portrait'
                    }
                }
            }
        },
        computed : {
            eventId : function() {
                return this.$route.params.id;
            }
        },
        methods: {
            generateData: async function(){           
                for (let i = 0; i < this.users.length; i++){
                    var tempselectedCheckpoints = [];
                    var tempDateOfWork = new Date();
    
                    await CheckpointService.getRobotCheckpoint(this.users[i]).then(
                        resp => {
                            var response = resp.status ? resp : resp.response; 
                            if(response.status == 200){
                                // clear selected checkpoints
                                response.data.forEach(i => tempselectedCheckpoints.push(
                                    // rebuild selected checkpoints
                                    new RobotCheckpointModel(i.checkpointId, i.direction, DateTime.fromISO(i.startDateTimeLocal, { setZone: true }), DateTime.fromISO(i.endDateTimeLocal, { setZone: true }))
                                ));
                                if (response.data.length != 0) {
                                    // update date of work with first selected checkpoint
                                    tempDateOfWork = tempselectedCheckpoints[0].startDate;
                                }
                            }
                            else{
                                
                                this.showToast(this.$t('error'), response.data, 'danger');
                            }
                        }
                    );
    
                    // Build DTO
                    this.pdfData.push({
                        user: this.users[i],
                        dateOfWork: tempDateOfWork,
                        selectedCheckpoints: tempselectedCheckpoints,
                    })
    
                    // Generate Report
                    if (i == (this.users.length - 1)){
                        this.generateReport();
                    }
                }
            },
            generateReport () {
                this.$refs.html2Pdf.generatePdf();
                setTimeout(function() {
                    this.pdfCreate = false;
                }.bind(this), 100);
            },
            createPDF : function() {
                this.pdfCreate = true;
                this.refreshEventDetails();
            },
            refreshEventDetails : function() {
                EventService.getEventDetails(this.eventId).then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        if(response.status == 200){
                            this.event = response.data;
                            this.generateData();
                        } 
                        else {
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                ).catch(error => {
                    this.showToast(this.$t('error'), error);
                })
            },
            checkpointName: function(id){
                if(this.checkpoints){
                    return this.checkpoints.find((i) => i.id == id).name;
                }
                return '';
            },
            onStartPagination(){
                this.$emit('onStartExportPDF');
            },
            onProgress(){
                this.$emit('onEndExportPDF');
            },
            getDate(luxonDateTime){
                return luxonDateTime.toLocaleString(DateTime.DATE_SHORT);
            },
            getTime(luxonDateTime){
                return luxonDateTime.toLocaleString(DateTime.TIME_24_SIMPLE);
            }
        },
    }
 </script>

 <style scoped>
    .card{overflow: visible;}
    .k4-pin{
        background-color: var(--dark);
        color:var(--main-top-font-color);
    }
    .k4-badge-absolute{position: relative;border: solid 0.1em var(--main-k4-primary-color);}
    .k4-badge-absolute .badge-light{background-color: white;}
    .k4-badge-absolute>.badge{position: absolute; top:-6px;left:-6px;}

    .k4-robot-pdf {
        background-color: transparent !important;
        /*border: solid 0.1em #2352a4 !important;*/
        color: #000000 !important;
    }

    .k4-clickable{cursor: pointer;}

    /* Avoid unexpected sizing on all elements. */
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    /* CSS styling for before/after/avoid. */
    .before {
        page-break-before: always;
    }
    .after {
        page-break-after: always;
    }
    .avoid {
        page-break-inside: avoid;
    }
    /* Big and bigger elements. */
    .big {
        height: 10.9in;
        background-color: yellow;
        border: 1px solid black;
    }
    .fullpage {
        height: 11in;
        background-color: fuchsia;
        border: 1px solid black;
    }
    .bigger {
        height: 11.1in;
        background-color: aqua;
        border: 1px solid black;
    }
    /* Table styling */
    table {
        border-collapse: collapse;
    }
    td {
        border: 1px solid black;
    }
</style>