<template>
    <transition name="oppening">
        <aside class="profile opened">
            <div class="w-100 h-100 p-md-5">
                <div v-on:click="close" class="close-profile pointer-click d-flex flex-fill justify-content-end">
                    <font-awesome-icon icon="times" class="fa-2x align-bottom text-black" />
                </div>
                <ProfileInfo />
            </div>
        </aside>
    </transition>
</template>

<script>
    import ProfileInfo from "./users/ProfileInfo.vue"

    export default {
        name: 'AsideUsers',
        components: { ProfileInfo },
        props: ['value'],
        methods: {
            close: function () {
                this.$emit('input', false);
            }
        },
        computed: {
            userName: function(){
                return this.$store.state.auth.user ? this.$store.state.auth.user.name : null;
            },
            roles : function(){
                return this.$store.state.auth.user ? this.$store.state.auth.user.roles : null;
            }
        }
    };
</script>

<style scoped>
    .oppening-enter-active,
    .oppening-leave-active {
        transition: transform 0.5s;
    }

    .oppening-enter,
    .oppening-leave-to {
        transform: translateX(440px);
    }

    .oppening-enter-to,
    .oppening-leave {
        transform: translateX(0px);
    }
</style>