<template>
    <div>
        <b-button variant="primary" @click="modalShow = !modalShow">
            <font-awesome-icon :icon="['fas','plus']" /><span v-if="!isSmallScreen">{{ $t("addUser") }}</span>
        </b-button>
        
        <b-modal v-model="modalShow" v-bind:title='$t("createUser")' size="lg">
            <div class="container-fluid">
                <div class="row pb-2">
                    <div class="col-lg-3">{{ $t("name")}}</div>
                    <div class="col-lg-9">
                        <input type="text" v-model="newUser.Name" class="form-control" />
                    </div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-3">{{ $t("email")}}</div>
                    <div class="col-lg-9">
                        <input type="text" v-model="newUser.Email" class="form-control" />
                    </div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-3">{{ $t("password")}}</div>
                    <div class="col-lg-9">
                        <input type="password" v-model="newUser.Password" class="form-control" />
                    </div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-3">{{ $t("validatePassword")}}</div>
                    <div class="col-lg-9">
                        <input type="password" v-model="verifPassword" class="form-control" />
                    </div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-3">{{ $t("group")}}</div>
                    <div class="col-lg-9">
                        <VueMultiselect
                            v-model="selectedGroup"
                            :options="groups"
                            :multiple="false"
                            :taggable="true"
                            @tag="addNewGroup"
                            :tag-placeholder="$t('createNewGroup')"
                            :placeholder="$t('searchGroup')"
                            label="name"
                            track-by="code"
                            :disabled="!this.canModifyGroup"
                        />
                    </div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-3">{{ $t("role")}}</div>
                    <div class="col-lg-9">
                        <div class="row">
                            <div class="col-lg-4" v-for="(role, index) in roles" v-bind:key="index" v-on:click="changeRole(role)">
                                <div class="card p-2 mb-3 k4-pointer text-center border rounded" :class="{'k4-card-selected' : isSelected(role)}">
                                    {{role.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0">
                    <div v-if="errorMessage.length" class="col-12 alert alert-danger">
                        <div v-for="(mess,index) in errorMessage" v-bind:key="index">{{mess}}</div>
                    </div>
                </div>
            </div>

            <template #modal-footer>
                <div class="w-100">
                    <button type="button" @click="modalShow = false" class="btn btn-outline-secondary mx-1">{{$t("cancel")}}</button>
                    <button type="button" @click="$event => createUser($event)" class="btn btn-success mx-1">{{$t("create")}}</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import UserService from '../../services/user.service'
    import utilMixin from '../../mixins/utils.mixins';
    import VueMultiselect from 'vue-multiselect'
    import { mapState } from 'vuex';

    export default {
        name:"AddApplicationUser",
        mixins: [utilMixin],
        components: { VueMultiselect },
        data: function() { 
            return{
                modalShow : false,
                verifPassword:'',
                roles: [],
                errorMessage: [],
                newUser:{
                    Name: '', 
                    Email: '',
                    Password: '',
                    Roles: []
                },
                selectedGroup: null,
                groups: []
            }
        }, 
        created : function(){
            UserService.getAllGroups().then(
                resp => {
                    var response = resp.status ? resp : resp.response;
                    if(response.status == 200) {
                        response.data.forEach(element => {
                            this.groups.push({ name: element.label, code: element.id });
                        });
                        // Set the new user's group to the user's group
                        if(!this.canModifyGroup){
                            this.selectedGroup = this.groups.find(group => group.code === this.$store.state.auth.user.groupId);
                        }
                    } 
                    else {
                        this.showToast(this.$t('error'), response.data, 'danger');
                    }
                }
            );

            UserService.getAllRoles("bo").then(
                resp => {
                    var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        this.roles = response.data;
                    } 
                    else {
                        this.showToast(this.$t("error"), response.data, 'danger');
                    }
                }
            );
        },
        methods:{
            addNewGroup (newGroupLabel) {
                newGroupLabel = newGroupLabel.toUpperCase();
                const tag = {
                    name: newGroupLabel,
                    code: this.groupsMaxId + 1
                }
                this.groups.push(tag);
                this.selectedGroup = tag;
            },
            createUser: function(event){
                if(event){
                    event.preventDefault();
                }

                if(!this.validForm()){
                    return;   
                }

                this.errorMessage = [];

                let selectedGroupValue = (this.selectedGroup !== null && this.selectedGroup !== undefined) ? this.selectedGroup.name : null;
                this.newUser.GroupLabel = selectedGroupValue;
                UserService.putUser(this.newUser).then(
                    valid => {
                            var response = valid.status ? valid : valid.response;

                            if(response.status == 200){
                                this.modalShow = false;
                                this.showToast(this.$t("userCreated"), this.newUser.Name + this.$t("hasBeenCreated"));
                                this.$emit('create_ok');
                            } 
                            else {
                                
                                this.errorMessage.push(response.data);
                            }
                        }
                    )
                    .catch(
                        error => {
                            this.modalShow = false;
                            this.showToast(this.$t("error"), error, 'danger');
                        }
                    );
            },
            validEmail: function(email){
                var expr = new RegExp(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/);
                return expr.test(email);
            },
            validForm: function() {
                this.errorMessage=[]; 

                if(!this.newUser.Name)
                    this.errorMessage.push(this.$t("pleaseEnterName"));
                if(!this.newUser.Email || !this.validEmail(this.newUser.Email))
                    this.errorMessage.push(this.$t("pleaseEnterValidMail"));
                if(!this.newUser.Password || this.verifPassword != this.newUser.Password)
                    this.errorMessage.push(this.$t("passwordAreDifferent"));
                if(this.newUser.Roles.length<=0)
                    this.errorMessage.push(this.$t("pleaseSelectARole"));

                return this.errorMessage.length==0;
            },
            changeRole: function(role){
                var index = this.newUser.Roles.findIndex(r => r.id == role.id);
                if(index<0){
                    this.newUser.Roles.push(role);
                }
                else{
                    this.newUser.Roles.splice(index,1);
                }
            },
            isSelected:function(role){
                var index = this.newUser.Roles.findIndex(r => r.id == role.id);
                return index >= 0;
            }
        },
        computed: {
            ...mapState(['isSmallScreen']),
            canModifyGroup(){
                return this.$store.state.auth.user.roles.includes("key4admin");
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>