<template>
    <b-modal v-if="(checkpointId > 0)" v-model="modalShow" scrollable :title="checkpointName" size="xl">
        <div class="input-group mb-4">
                <input id="searchInput" type="text" v-model="searchQuery" v-on:keyup="filterItems" class="form-control" v-bind:placeholder="$t('searchOnBarcode')" aria-label="Search" aria-describedby="basic-addon3">
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon3">
                        <font-awesome-icon :icon="['fas','search']" size="1x" />
                    </span>
                </div>
        </div>
        <div class="container-fluid">   
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="amount" aria-controls="my-table" />
            <div v-if="completed" style="display: inline-block; position: absolute; top: 55px; right: 30px;" class="text-right p-3"><span class="spinner-border spinner-border-sm mr-3"></span>{{ $t('loading') }}</div>
            
            <b-table responsive outlined striped hovers show-empty :items="filteredbarcodes" :fields="tableFields" :per-page="amount" :current-page="currentPage">
                <!-- Texts to show when the table is empty -->
                <template #empty>
                    <div class="text-center my-3">{{ $t("noDataToDisplay") }}</div>
                </template>
                <template #emptyfiltered>
                    <div class="text-center my-3">{{ scope.emptyFilteredText }}</div>
                </template>

                <template v-slot:cell(lastUpdateDt)="data">
                    <div class="text-nowrap">
                        <span v-if="data.value">{{ this.$DateTimeService.GetDateTimeFromISO(data.value) }}</span>
                    </div>
                </template>
                <template v-slot:cell(numberCheckedIn)="row">
                    <span v-if="row.item.numberOfAllowedCheckedIn === -1">
                        {{row.item.currentCheckedIn}} / ∞
                    </span>
                    <span v-else-if="row.item.numberOfAllowedCheckedIn > 0">
                        {{row.item.currentCheckedIn}} / {{row.item.numberOfAllowedCheckedIn}}
                    </span>
                </template>
                <template v-slot:cell(tag)="row">
                    <div class="text-right">
                        <span v-if="(row.item.numberOfAllowedCheckedIn === -1 || row.item.numberOfAllowedCheckedIn > 0)" class="badge badge-success py-1 px-2">{{ $t('authorized') }}</span>
                        <span v-if="(row.item.numberOfAllowedCheckedIn == 0)" class="badge badge-warning py-1 px-2">{{ $t('unauthorized') }}</span>
                        <span v-if="(row.item.alreadyCheckedIn > 0)" class="badge badge-warning py-1 px-2 ml-1">{{ $t('checkedIn') }}</span>
                        <span v-if="(row.item.isPresent)" class="badge badge-warning py-1 px-2 ml-1">{{ $t('present') }}</span>
                        <span v-if="!row.item.alive" class="badge badge-danger py-1 px-2 ml-1">{{ $t('notAlive') }}</span>
                    </div>  
                </template>
            </b-table>
            <b-button v-if="canAskUnauthorized && currentPage >= (rows/amount)" class="btn btn-primary" @click="refreshBarCodeListFilter('UNAUTHORIZED')">{{ $t('loadUnhauthCode') }}</b-button>
        </div>

        <template #modal-footer>
            <button type="button" @click="handleReset" class="btn btn-outline-secondary mx-1">{{$t('close')}}</button>
        </template>
    </b-modal>
</template>

<script>
import KnowBarcodeService from '../../services/knowbarcode.service';

export default {
    name : 'barcodeList',
    data : function(){
        return {
            checkpointId : 0,
            checkpointName : '',
            barcodes:[],
            searchQuery: '',
            filteredbarcodes:[],
            completed:true, 
            modalShow : false,
            page : 0,
            amount : 200,
            currentPage : 1,
            canAskUnauthorized : false,
            reset: false
        };
    },
    watch : {
        checkpointId : function(val){
            if(val > 0 && this.eventId > 0 ){
                this.refreshBarCodeList();
            }
        },
        modalShow : function(val){
            if(!val) {
                this.checkpointId = 0;
                this.barcodes = [];
                this.searchQuery = '';
                this.filteredbarcodes = [];
            }
        }
    },
    methods:{
        refreshBarCodeList: function(){
            this.reset = false;
            this.barcodes = [];
            this.refreshBarcodeListFunction();
        },
        refreshBarcodeListFunction(filter, p, a){
            if (this.page == 0){
                this.completed = true;
                this.canAskUnauthorized = false;
            }
            if(p){
                this.page = p;
            }
            if(a){
                this.amount = a;
            }
            if(this.checkpointId == 0 || this.eventId == 0 ){
                return;
            }

            var model  = {
                page : this.page,
                amount : this.amount
            }

            KnowBarcodeService.getKnowbarcodeByPage(model, this.eventId, this.checkpointId, filter).then(
                resp =>{
                    var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        this.barcodes = [...this.barcodes, ...response.data];
                        this.filterItems();
                        if(response.data.length == this.amount && !this.reset){
                            this.page = this.page + 1;
                            this.refreshBarcodeListFunction(filter, this.page, this.amount);
                        }
                        else{
                            this.page = 0;
                            this.completed = false;
                            this.reset = false;
                            if(!filter){
                                this.canAskUnauthorized = true;
                            }
                        }
                    } else {
                        
                        this.showToast('Error', response.data, 'danger');
                    }
                }
            ).catch(
                error => {
                    
                    this.showToast('Error', error, 'danger');
                }
            );
        },
        handleReset: function() {
            this.reset = true;
            this.modalShow = false;
        },
        refreshBarCodeListFilter: function(filter){
            this.refreshBarcodeListFunction(filter, this.page, this.amount);
        },
        showModal : function() {
            this.modalShow = true;
        },
        showToast: function(title, body, variant = 'default'){
            this.$bvToast.toast(body, {
                title: title ,
                autoHideDelay: 5000,
                variant: variant,
                appendToast: false
            });
        },
        filterItems : function(){
            this.filteredbarcodes = this.barcodes.filter(item =>{
            var itemText = item.barcode + "|" + item.resultDisplayContentWhenDisallowed + "|" + (!item.alive > 0 ? this.$t("notAlive") : "") + "|" + (item.isPresent > 0 ? this.$t("present") : "") + "|" + (item.numberOfAllowedCheckedIn > 0 ? this.$t('authorized') : this.$t('unauthorized')) + "|" + (item.alreadyCheckedIn > 0 ? this.$t('checkedIn') : ""); 
            return itemText.toLowerCase().includes(this.searchQuery.toLowerCase());
            });
        }
    },
    computed: {
        eventId : function() {
            return this.$route.params.id;
        },
        tableFields: function() {
            return [
                { key : 'barcode', label: this.$t("barcode")},
                { key : 'resultDisplayContentWhenDisallowed', label: this.$t("label")},
                { key : 'numberCheckedIn', label: this.$t("passages")},
                { key : 'tag', label : this.$t("tags")}
            ];
        },
        rows() {
            return this.filteredbarcodes.length
        }
    }
}
</script>

<style scoped>
    .container-fluid .row{margin-bottom:5px;}
</style>