<template>
    <div>
        <b-dropdown id="dropdown-left" variant="primary" no-caret dropleft v-if="isSuperAdmin()">
            <template #button-content>
                <font-awesome-icon :icon="['fas','plus']" />
                <span v-if="!isSmallScreen">{{ $t("addEvent") }}</span>
            </template>

            <b-dropdown-item @click="modalShow = !modalShow"><font-awesome-icon icon="list" class="mr-2 text-success" style="font-size: 20px;" />{{ $t('importFromList') }}</b-dropdown-item>
            <b-dropdown-item @click="newModalShow = !newModalShow"><font-awesome-icon icon="pen" class="mr-2 text-success" style="font-size: 20px;" />{{ $t('importManual') }}</b-dropdown-item>
        </b-dropdown>

        <b-modal v-model="modalShow" v-bind:title='$t("importEvent")' size="lg" v-bind:ok-title='$t("import")'>
            <div class="container-fluid">
                <div class="col-xs-12 p-3 mb-1 card event"  :class="{'k4-card-selected' : index == selectedIndex}" v-for="(item, index) in eventList" v-bind:key="index" v-on:click="selectItem(index)" style="cursor: pointer;">
                    <div class="row">
                        <div class="px-3" style="width:70px">{{item.eventId}}</div>
                        <div><strong>{{item.manifLib}}</strong></div>
                    </div>
                </div>
            </div>

            <template #modal-footer>
                <div class="w-100">
                    <button type="button" @click="modalShow = false" class="btn btn-outline-secondary mx-1">{{$t("cancel")}}</button>
                    <button type="button" @click="$event => validImportEvent()" class="btn btn-success mx-1">{{$t("import")}}</button>
                </div>
            </template>
        </b-modal>

        <b-modal v-model="newModalShow" v-bind:title='$t("importEvent")' size="lg">
            <div class="container-fluid">
                <div class="row pb-2">
                    <div class="col-lg-3">{{$t("eventUrl")}}</div>
                    <div class="col-lg-9">
                        <input type="text" v-model="newEventUrl" class="form-control" />
                    </div>
                </div>
                <div class="row pb-2">
                    <div class="col-lg-3">{{$t("eventHash")}}</div>
                    <div class="col-lg-9">
                        <input type="text" v-model="newEventHash" class="form-control" />
                    </div>
                </div>
            </div>

            <template #modal-footer>
                <div class="w-100">
                    <button type="button" @click="newModalShow = false" class="btn btn-outline-secondary mx-1">{{$t("cancel")}}</button>
                    <button type="button" @click="$event => validManualImportEvent()" class="btn btn-success mx-1">{{$t("import")}}</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
    import EventService from '../../services/event.service';
    import UserService from '../../services/user.service';
    import utilMixin from '../../mixins/utils.mixins';
    import { mapState } from 'vuex';

    export default {
        name : 'AddEvent',
        mixins: [utilMixin],
        data :function(){return {
            modalShow: false,
            newModalShow: false,
            newEventUrl: "",
            newEventHash: "",
            eventList:[],
            selectedIndex: 0,
            selectedEvent:{}
        }},
        created: function(){
            EventService.getImportEventList('').then(lst => {
                if(lst && lst.data){
                    this.eventList = lst.data; 
                    this.selectedIndex=0;
                    this.selectedEvent = lst.data[0];
                }
            });
        },
        methods: {
            validImportEvent: function(){
                if(!this.selectedEvent) {
                    return;
                }
                EventService.importEvent(this.selectedEvent).then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        this.modalShow = false;
                        if(response.status == '200'){
                            
                            this.showToast(this.$t("importEvent"), response.data);
                            this.$emit('import_ok');
                        } 
                        else {
                            
                            this.showToast(this.$t("error"), response.data, 'danger');
                        }
                    }
                );
                
            },
            validManualImportEvent: function(){           
                var model = { 
                    apiUrl : this.newEventUrl,
                    apiKey : this.newEventHash
                };

                EventService.importEventManual(model).then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        if(response.status == '200'){
                            this.newModalShow = false;
                            this.newEventUrl = "";
                            this.newEventHash = "";
                            
                            this.showToast(this.$t("importEvent"), response.data);
                            this.$emit('import_ok');
                        } 
                        else {
                            this.showToast(this.$t("error"), response.data, 'danger');
                        }
                    }
                );
                
            },
            selectItem: function(index){
                this.selectedIndex = index; 
                this.selectedEvent = this.eventList[index];
            },
            isSuperAdmin: function(){
                return UserService.isSuperAdmin();
            }       
        },
        computed: {
            ...mapState(["isSmallScreen"])
        }
    }
</script>
<style>
    .k4-selected{
        color: var(--main-top-font-color); 
        background-color: var(--main-top-bg-color);
    }

    .event:hover {
        border: 1px solid #2352a4 !important;
    }
</style>