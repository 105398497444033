<template>
    <div class="k4-page">
        <section class="w-100 alert alert-warning d-flex justify-content-between align-items-center" role="alert" v-if="event.eventStatus == 0">
            <div class="float-left"><font-awesome-icon icon="exclamation-triangle" class="mr-2" style="font-size: 22px;" /><span style="font-size: 16px;">{{ $t('warningTestEvent')}}</span></div>
            <div class="float-right">
                <b-dropdown variant="success" id="dropdown-dropleft" dropleft no-caret v-if="event.eventStatus == 0">
                    <template #button-content>
                        <font-awesome-icon icon="tower-broadcast" />
                        <span>{{ $t('productionMode') }}</span>
                    </template>

                    <b-dropdown-item @click="productionMode(false)"><font-awesome-icon icon="file-circle-check" class="mr-2" style="font-size: 20px;" />{{ $t('keepData') }}</b-dropdown-item>
                    <b-dropdown-item @click="productionMode(true)"><font-awesome-icon icon="file-circle-xmark" class="mr-2" style="font-size: 20px;" />{{ $t('deleteData') }}</b-dropdown-item>
                </b-dropdown>
            </div>
        </section>

        <section class="row mt-5">
            <div class="col">
                <div class="title">
                    <span class="text-center align-middle pointer-click mr-2" v-on:click="goHome">
                        <font-awesome-icon class="k4-global-backIcon" icon="circle-left" size="2x"/>
                    </span>
                    <h2 class="d-inline mb-0 align-middle text-primary-dark">{{ $t("dashboard") }} // {{event.name}}</h2>
                </div>
            </div>
        </section>

        <div v-show="isAdmin()" class="row pt-4">
            <div class="col-lg-4">
                <SectionComponent v-bind:title="$t('generalInformation')" icon="info-circle">
                    <DetailsEvent id="generalInformation" @vue:updated="updateOnSiteStaffHeight" v-if="event" :event="event" @click_SynchroEvent="synchroCheckpoints" @synchroEvent="refreshEventDetails" />
                </SectionComponent>
            </div>
            <div class="col-lg-8">
                <SectionComponent v-bind:title="$t('onSiteStaff')" icon="address-book" ref="onSiteStaffComponent">
                    <DeviceUserList :event="event" />
                </SectionComponent>
            </div>
        </div>
        <b-button variant="primary" class="btn-statistics float-md-end" @click="goToStats(event.id)"><font-awesome-icon :icon="['fas','chevron-right']" size="1x" /> {{ $t('statisticsMore') }}</b-button>
        <SectionComponent v-bind:title="$t('generalStats')" icon="chart-pie">
            <StatEvent v-if="event" :event="event" />
        </SectionComponent>
        <SectionComponent v-bind:title="$t('eventsStats')" icon="clipboard-check">
            <CheckpointList v-if="event" :event="event" />
        </SectionComponent>
        <ProductionConfirm ref="myConfirmProductionMode" @update_production_ok="refreshAll" />
    </div>
</template>

<script>
    import UserService from '../services/user.service';
    import EventService from '../services/event.service';
    import CheckpointService from '../services/checkpoint.service';
    import KnowbarcodeService from '../services/knowbarcode.service';

    import DetailsEvent from '../components/events/DetailsEvent';
    import DeviceUserList from '../components/users/DeviceUserList';
    import StatEvent from '../components/events/StatEvent';
    import CheckpointList from '../components/checkpoints/CheckpointList';
    import SectionComponent from '../components/SectionComponent';

    import ProductionConfirm from '../components/events/ProductionMode.vue';

    import utilMixin from '../mixins/utils.mixins';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { useHead } from 'unhead'
    import { mapState } from 'vuex';

    export default {
        name:"EventView",
        mixins: [utilMixin],
        data : function (){
            return {
                event : {},
                checkpoints : [],
                isCheckpointLoading : true
            }
        },
        computed: {
            ...mapState(["isSmallScreen"]),
            eventId : function() {
                return this.$route.params.id;
            },
        },
        created : function(){
            this.refreshEventDetails();
        },
        mounted() {

        },
        methods: {
            updateOnSiteStaffHeight() {
                if(!this.isSmallScreen){
                    // return left, top, right, bottom, x, y, width, and height properties
                    const getBoundingClientRectHeight = document.getElementById('generalInformation').getBoundingClientRect().height;
                    this.$refs.onSiteStaffComponent.updateHeight(getBoundingClientRectHeight);
                }
            },
            goHome: function () {
                this.$router.replace({ name: "Root" });
            },
            goToStats: function(id){
                this.$router.push({ path:  id + '/statistics' });
            },
            productionMode: function(eraseAllData){
                this.$refs.myConfirmProductionMode.eventId = this.eventId;
                this.$refs.myConfirmProductionMode.eraseAllData = eraseAllData;
                this.$refs.myConfirmProductionMode.showModal();
            },
            refreshAll : function () {
                this.refreshEventDetails();
                this.synchroCheckpoints();
            },
            refreshEventDetails : function() {
                EventService.getEventDetails(this.eventId).then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        if(response.status == 200){
                            this.event = response.data;
                            useHead({
                                title: this.event.name + " | K4access"
                            })
                        }
                        else {
                            this.showToast(this.$t('error'), response.data, 'danger');
                            this.$router.push({ name: "Events" });
                        }
                    }
                ).catch(error => {
                    this.showToast(this.$t('error'), error);
                    this.$router.push({ name: "Events" });
                })
            },
            synchroCheckpoints : function(){
                this.isCheckpointLoading = true;
                
                this.showToast(this.$t('checkpoints'), this.$t('syncRunningWait'));
                CheckpointService.importCheckpoints(this.eventId).then(
                    resp => {
                        this.isCheckpointLoading = false;
                        var response = resp.status ? resp : resp.response;
                        
                        this.showToast(response.status == 200 ? this.$t('checkpoints') : this.$t('error'), response.data, response.status == 200 ? 'default' : 'danger');
                        if(response.status == 200){
                            this.synchroKnowBarcodes();
                        }
                    },
                    error => {
                        this.isCheckpointLoading = false;
                        
                        this.showToast(this.$t('error'), error.data, 'danger');
                    }
                ).catch(
                    error => {
                        this.isCheckpointLoading = false;
                        
                        this.showToast(this.$t('error'), error, 'danger');
                    }
                )
            },
            synchroKnowBarcodes : function(){
                if(!this.checkpoints.length)
                    return;

                this.checkpoints.forEach(checkpoint => {
                    KnowbarcodeService.syncKnowbarcodes(this.eventId, checkpoint.id).then(
                        resp => {
                            var response = resp.status ? resp : resp.response; 
                            if(response.status == 200){
                                
                                this.showToast(this.$t('synchroKnowbarcode'), response.data);
                            } else {
                                
                                this.showToast(this.$t('error'), response.data, 'danger');
                            }
                        },
                        error => {
                            
                            this.showToast(this.$t('error'), error.data, 'danger');
                        }
                    ).catch(
                        error => {
                            
                            this.showToast(this.$t('error'), error, 'danger');
                        }
                    );
                });
                
            },
            isAdmin : function() {
                var isAdmin = UserService.isAdmin();
                return isAdmin ? isAdmin : false ;
            }
        },
        components : { DetailsEvent, DeviceUserList, CheckpointList, StatEvent, SectionComponent, ProductionConfirm, FontAwesomeIcon }
    }
</script>
<style scoped>
    .k4-card-title{font-size: 22px;font-variant: small-caps;}

    .btn-statistics{
        right: 0%;
        margin-top:50px;
    }
</style>