<template>
    <div class="card shadow">
        <div class="card-body">
            <div class="row">
                <section class="col-12">
                    <div class="row">
                        <div class="col-lg-3">{{ $t("name") }}</div>
                        <div class="col-lg-9">{{event.name}}</div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">{{ $t("startDate") }}</div>
                        <div class="col-lg-9">{{this.$DateTimeService.GetDateFromISO(event.startDate)}}</div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">{{ $t("lastSync") }}</div>
                        <div class="col-lg-9" v-if="event.lastSync">{{this.$DateTimeService.GetDateTimeFromISO(event.lastSync, true)}}</div>
                        <div class="col-lg-9" v-if="!event.lastSync"><span class="badge badge-warning py-1 px-2">{{ $t("NOT_SYNC") }}</span></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">{{ $t("timezone") }}</div>
                        <div class="col-lg-9">(UTC {{event.utcOffset}}) {{ event.timezoneIso }}</div>
                    </div>
                </section>

                <div class="d-flex justify-content-center my-5">
                    <Qrcode v-if="event.qrCode" :text="event.qrCode" :size="150" color="#2352a4"></Qrcode>
                </div>

                <section class="col-12">
                    <div class="d-flex">
                        <button class="btn btn-secondary w-50 mr-2" @click="showDetails()" v-if="isAdmin()"><font-awesome-icon icon="gear" size="1x" class="mx-1" />{{ $t('config') }}</button>
                        <ButtonExportPDF ref="ButtonExportPDF" class="w-50" @click.native="getInformation()"></ButtonExportPDF>
                    </div>

                    <div class="d-flex my-2">
                        <button :class="{'btn' : true, 'btn-primary' : true, 'w-50' : isKey4Admin(), 'w-100' : !isKey4Admin(), 'mr-2' : isKey4Admin() }" @click="exportScans()"><font-awesome-icon icon="upload" size="1x" />{{ $t('exportScansRegister') }}</button>
                        <button v-if="isKey4Admin()" class="btn btn-info w-50" @click="showLogFiles()"><font-awesome-icon icon="book" size="1x" />Logs</button>
                    </div>
                </section>                
            </div>
            <EventConfig ref="detailsModal" @synchroEvent="synchroEvent" />
            <GeneratePDF ref="eventPdf" @onEndExportPDF="onEndExportPDF" />
            <ModalLogs v-if="isKey4Admin()" ref="modalLogs" />
        </div>
    </div>
</template>

<script>
import Qrcode from 'vue-qrcode-component';
import UserService from '../../services/user.service';
import KnowBarcodeService from '../../services/knowbarcode.service';
import EventConfig from './EventConfig';
import GeneratePDF from '../pdf/GeneratePDF.vue'
import ButtonExportPDF from "../pdf/ButtonExportPDF.vue";
import ModalLogs from './ModalLogs';

export default {
    name : 'DetailsEvent',
    props:['event'],
    components:{ Qrcode, EventConfig, GeneratePDF, ButtonExportPDF, ModalLogs },
    methods : {
        onEndExportPDF(){
            this.$refs.ButtonExportPDF.onEndExportPDF();
        },
        synchroCheckpoints : function(){
            this.$emit('click_SynchroEvent');
        },
        synchroEvent : function(){
            this.$emit('synchroEvent');
        },
        isKey4Admin: function(){
            return UserService.isSuperAdmin();
        },
        isAdmin : function() {
            var isAdmin = UserService.isAdmin();
            return isAdmin ? isAdmin : false ;
        },
        showDetails : function() {
            this.$refs.detailsModal.showModal();
        },
        showLogFiles: function(){
            this.$refs.modalLogs.showModal();
        },
        getInformation: function() {
            this.$refs.ButtonExportPDF.onStartExportPDF();
            this.$refs.eventPdf.createPDF();
        },
        exportScans: function() {
            
            this.showToast('Exporting', 'Exporting scans to the Register in-progress..');

            KnowBarcodeService.exportAllScans(this.eventId).then(
                resp =>{
                    var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        if (response.data == true){
                            this.showToast('Success', 'All scans have been successfully exported to the Register!');
                        }
                        else {
                            
                            this.showToast('Error', 'An error occured during the export. Please contact an administrator.', 'danger');
                        }
                    } else {
                        
                        this.showToast('Error', response.data, 'danger');
                    }
                }
            ).catch(
                error => {
                    this.showToast('Error', error, 'danger');
                }
            );
        },
        showToast: function(title, body, variant = 'default'){
            this.$bvToast.toast(body, {
                title: title ,
                autoHideDelay: 5000,
                variant: variant,
                appendToast: false
            });
        }
    },
    computed: {
        eventId: function() {
            return this.$route.params.id;
        }
    }
}
</script>

<style scoped>
    .k4-actionButton {
        font-size: 14px;
    }
</style>