<template>
<div class="k4-page">
    <h2>Dashboard</h2>

</div>
</template>
<script>
export default {
    name: "DashboardView"
}
</script>