import { createStore } from 'vuex'

import{ auth } from './auth.module.js';

export default createStore({
  state: {
    language: "en",
    isSmallScreen: window.innerWidth <= 768
  },
  getters: {
    getLanguage: (state) => {
      return state.language;
    },
    getIsSmallScreen: (state) => {
      return state.isSmallScreen
    }
  },
  mutations: {
    UPDATE_LANG(state, lang) {
        state.language = lang;
    },
    setIsSmallScreen(state, isSmallScreen){
      state.isSmallScreen = isSmallScreen;
    }
  },
  // Actions are typically used to perform tasks like making API calls, 
  // handling complex asynchronous operations, or executing multiple mutations based on 
  // certain conditions.
  actions: {
    changeLanguage({ commit }, payload) {
        payload.i18n.locale = payload.lang;
        commit('UPDATE_LANG', payload.lang);
    }
  },
  modules: {
    auth
  }
});
