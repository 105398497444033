<template>
    <div class="card-body container-fluid" v-if="!done">
      <div class="text-center p-3"><span class="spinner-border spinner-border-sm mr-3"></span>{{ $t('loading') }}</div>
    </div>
    <div v-else-if="this.done && this.RushHourChartData.data.datasets.length === 0" class="d-flex justify-content-center align-items-center h-100">
        <NoData></NoData>
    </div>
    <LineVueChartJS v-else :data="RushHourChartData.data" :options="RushHourChartData.options" />
</template>

<script>
  import StatisticsService from '../../services/statistics.service';
  import utilMixin from '../../mixins/utils.mixins';
  import NoData from '../NoData';

  import { Line as LineVueChartJS } from 'vue-chartjs';
  import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  export default {
    name: 'RushHourChart',
    mixins: [utilMixin],
    props: ['checkpoints'],
    components: { LineVueChartJS, NoData },
    data() {
      return {
        done: false,
        RushHourChartData: {
          type: "line",
          data: {
            labels: [],
            datasets: []
          },
          options: {
            tension: 0.4,
            responsive: true,
            plugins: {
                title: {
                    display: false
                }
            }
          }
        }
      }
    },
    computed: {
      eventId : function() {
          return this.$route.params.id;
      }
    },
    mounted() {
      this.getStatistics();
    },
    methods: {
      getStatistics: function() {
        this.done = false;
        StatisticsService.getRushHourStatistics(this.eventId, this.checkpoints).then(
            resp => {
                var response = resp.status ? resp : resp.response; 
                if(response.status == 200){
                    let data = response.data;

                    if (data.length > 0) {
                      // filling labels first
                      for (let i = 0; i < 24; i++) {
                        if (i < 10){
                          if (data[0]['amount']['0' + i] != -1){
                            this.RushHourChartData["data"]["labels"].push('0' + i + "h");
                          }
                        }
                        else{
                          if (data[0]['amount'][i] != -1){
                            this.RushHourChartData["data"]["labels"].push(i.toString() + "h");
                          }
                        }
                      }

                      // filling data
                      for (let i = 0; i < data.length; i++){
                        let amountData = [];
                        for (let j = 0; j < 24; j++) {
                          if (j < 10) {
                            if (data[i]['amount']['0' + j] != -1){
                              amountData.push(data[i]['amount']['0' + j]);
                            }
                          }
                          else{
                            if (data[i]['amount'][j] != -1){
                              amountData.push(data[i]['amount'][j]);
                            }
                          }
                        }

                        this.RushHourChartData["data"]["datasets"].push({
                            label: data[i]["date"],
                            data: amountData,
                            backgroundColor: "rgba(0,0,0,0)",
                            borderColor: this.colorsHex[i],
                            borderWidth: 2
                        });
                      }
                    }

                    // Creating the chart
                    this.done = true;
                } 
                else {
                    this.showToast(this.$t('error'), response.data, 'danger');
                    this.done = true;
                }
            },
            error => {
                this.showToast(this.$t('error'), error.data, 'danger');
                this.done = true;
            }
        ).catch(
            error => {
                this.showToast(this.$t('error'), error, 'danger');
                this.done = true;
            }
        );
      }
    }
  }
</script>