export default {
    data() {
        return {
            colorsHex: ['#E67390', '#76B57E', '#FFEE80', '#8D9DD9', '#F5AD7A', '#A05BB5', '#7ADEF5', '#F078EA', '#CEF078', '#fabed4', '#469990', '#dcbeff', '#99754C', '#fffac8', '#804040', '#aaffc3', '#808040', '#ffd8b1', '#3B3B75', '#a9a9a9', '#ffffff', '#000000']
        }
    },
    methods: {
        showToast: function(title, body, variant = 'default'){
            if(title.length > 0 && body.length > 0){
                this.$bvToast.toast(body, {
                    title: title,
                    autoHideDelay: 5000,
                    variant: variant,
                    appendToast: false
                });
            }
        }
    }
}