<template>
    <ul class="k4-userMenu__container user-options d-flex list-unstyled">
        <li class="my-auto lang-selector dropdown m-1 pointer-click" v-on:click="changeLanguage({ lang: 'fr', i18n: $i18n })">
            <span v-bind:class="getClassObject('fr')">FR</span>
        </li>
        <li class="my-auto lang-selector dropdown m-1 pointer-click" v-on:click="changeLanguage({ lang: 'en', i18n: $i18n })">
            <span v-bind:class="getClassObject('en')"><p>EN</p></span>
        </li>
    </ul>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    export default {
        name: 'LanguageSelector',
        props: {
            backgroundColorClass: {
                type: String,
                default: '' // If the prop is not provided, it will default to an empty string
            }
        },
        components: { },
        methods: {
            ...mapActions([
                'changeLanguage'
            ]),
            getClassObject(languageOption) {
                return {
                    // Add other class bindings here
                    'active': languageOption === this.language, // Add the class provided by the prop
                    [this.backgroundColorClass]: true // Use the value of the prop as a class name
                };
            },
        },
        computed: {
            ...mapState(["language"])
        },

    };
</script>

<style scoped>

</style>