<template>
    <header class="k4-header d-flex justify-content-between align-items-center flex-wrap p-0 bg-primary-dark">
      <div class="h-100 d-flex justify-content-start align-items-center">
        <div v-on:click="goHome" class="h-100 pointer-click d-flex justify-content-center" :class="isMenuDrawerOpened ? '' : 'k4-navigationBar--closed'">
          <img v-if="isMenuDrawerOpened" src="@/assets/key4events_logo_text_white.svg" alt="Key4Events Logo" class="img-fluid m-4"/>
          <img v-else src="@/assets/key4access_logo_image_blue_background.png" alt="K4access Logo" class="img-fluid"/>
        </div>

        <div v-if="!isSmallScreen" class="d-flex align-items-center">
          <div class="k4-header__openCloseDrawer d-flex justify-content-center align-items-center" role="button" @click="onMenuIconClick()">
            <font-awesome-icon v-if="isMenuDrawerOpened" icon="arrow-left" class="text-white" size="2x"></font-awesome-icon>
            <font-awesome-icon v-else icon="bars" class="text-white" size="2x"></font-awesome-icon>
          </div>

          <div v-on:click="goHome" class="h-100 d-flex align-items-center my-auto pointer-click mx-5">
            <div v-if="!isSmallScreen" class="k4-header__title d-flex text-white">{{ $t("title") }}</div>
          </div>
        </div>
        <div v-else>
          <MainMenuSmall />
        </div>
      </div>

      <div v-if="!isSmallScreen" class="h-100 d-flex align-items-center">
        <ul class="k4-userMenu__container user-options d-none d-lg-flex list-unstyled p-0 pl-3 m-0 ml-2 ml-lg-3">
          <li><AuthenticationComponent v-on:toggle="openUserMenu" /></li>
          <li class="mx-3"><LanguageSelector backgroundColorClass="bg-success" /></li>
          <li class="my-auto logout mr-3 mr-lg-4 pointer-click">
              <span class="k4-userMenu__container__logout d-inline-block op-6 op-h-10" v-bind:title='$t("disconnect")' v-on:click="logout">
                  <font-awesome-icon icon="sign-out-alt" class="k4-userMenu__container__logout__icon" />
              </span>
          </li>
        </ul>
      </div>
      <div v-else v-on:click="openUserMenu" class="d-flex justify-content-end align-items-center k4-pointer">
        <font-awesome-icon icon="bars" class="text-white mx-3" size="2x"></font-awesome-icon>
      </div>
    </header>   
</template>

<script>
  import LanguageSelector from './users/LanguageSelector.vue';
  import AuthenticationComponent from './AuthenticationComponent';
  import MainMenuSmall from "./small_screens/MainMenuSmall"
  import { mapState } from 'vuex';

  export default {
      name: 'MainHeader',
      props: {
        value: Boolean
      },
      components: {
        AuthenticationComponent,
        MainMenuSmall,
        LanguageSelector
      },
      data() {
        return {
          
        };
      },
      mounted() {

      },
      methods: {
        goHome: function () {
          this.$router.push({ name: "Root" });
        },
        logout: function(){
          this.$store.dispatch('auth/logout');
          this.$router.replace({ name: 'Login' });
        },
        openUserMenu: function () {
          this.$emit('toggle');
        },
        onMenuIconClick(){
          this.$emit("on-menu-icon-click");
        }
      },
      computed: {
        ...mapState(['isSmallScreen']),
        isMenuDrawerOpened: {
          get() {
            return this.value;
          }
        }
      }
  }
</script>

<style scoped>
  .k4-userMenu__container {
    height: 28px;
  }
  .k4-userMenu__container__logout {
    height: 28px;
    width: 28px;
    color: var(--white);
  }
  .k4-userMenu__container__logout__icon {
    height:28px;
    width:28px;
  }
  .k4-header {
    min-height: 64px;
    max-height: 64px;
  }
  .k4-header__openCloseDrawer {
    border-radius: 50%; 
    width: 45px; 
    height: 45px;
  }
  .k4-header__openCloseDrawer:hover {
    background: var(--indigo);
  }
  .k4-header__title {
    font-size: 1.7rem;
  }
  .k4-navigationBar--closed {
    flex: 0 0 98px !important;
    width: 98px;
  }
</style>