<template>
    <div class="card shadow h-100 d-flex flex-column container">
        <AddUser ref="AddUser" class="my-3" :eventId="event.id" @create_ok="refreshUserList" @export_all="exportPDF"/>
        <div class="row mh-100 overflow-auto p-3">
            <div v-if="users.length == 0" class="col-12">
                <div class="alert alert-warning">{{ $t("deviceUserlistEmpty") }}</div> 
            </div>

            <div v-for="user in users" :key="user.id" class="p-0 card col-md-4 shadow-sm" role="button" v-on:click="updateUser(user)">
                <div v-if="!user.isRobotUser" class="k4-deviceUserCard border border-dark row align-items-center m-1">
                    <div class="col-8 text-center p-2">
                        <span>{{user.name}}</span>
                    </div>
                    <div class="col-4 text-center bg-primary-dark text-white p-2">
                        <span v-if="user.checkpoints > 0" class="badge bg-white text-black border" title="number of attached checkpoints">{{user.checkpoints}} cp</span>
                        <span v-if="user.checkpoints == 0" class="badge badge-warning" title="no checkpoint for this user">no cp</span>
                        <p class="m-0">{{user.pin}}</p>
                    </div>
                </div>
                <div v-else class="k4-deviceUserCard border border-dark row align-items-center m-1">
                    <div class="col-8 text-center p-2">
                        <span><font-awesome-icon class="k4-robotIcon" :icon="['fas','robot']" size="1x" /> {{user.name}}</span>
                    </div>
                    <div class="col-4 text-center bg-gray-dark text-white p-2">
                        <span v-if="user.checkpoints > 0" class="badge bg-white text-black bg-white border" title="number of attached checkpoints">{{user.checkpoints}} cp</span>
                        <span v-if="user.checkpoints == 0" class="badge badge-warning" title="no checkpoint for this user">no cp</span>
                        <p class="m-0">{{user.pin}}</p>
                    </div>
                </div>
            </div>
        </div>
        <UpdatePinUser ref="updatePinUserModal" @action_ok="refreshUserList" @delete_ok="refreshUserList" />
        <UpdatePinRobot ref="updatePinRobotModal" @action_ok="refreshUserList" @delete_ok="refreshUserList" :event="event" />
        <GenerateRobotPDF ref="robotPdf" @onEndExportPDF="onEndExportPDF" />
    </div>
</template>

<script>
    import UserService from '../../services/user.service';
    import CheckpointService from '../../services/checkpoint.service';
    import AddUser from './AddPinUser';
    import UpdatePinUser from './UpdatePinUser';
    import UpdatePinRobot from './UpdatePinRobot';
    import utilMixin from '../../mixins/utils.mixins';
    import GenerateRobotPDF from '../pdf/GenerateRobotPDF.vue';

    export default {
        name:'DeviceUserList',
        mixins: [utilMixin],
        props : ['event'],
        components:{AddUser, UpdatePinUser, UpdatePinRobot, GenerateRobotPDF},
        data : function(){
            return {
                users : [],
                checkpoints:[],
                IsRobotEnabled : false
            }
        },
        watch: {
            event : function(val){
                if(val && val.id){
                    this.refreshUserList();
                }
            }
        },
        created: function(){
            if(this.event && this.event.id){
                this.refreshUserList();
            }
        },
        methods:{
            onEndExportPDF(){
                this.$refs.AddUser.onEndExportPDF();
            },
            refreshUserList : function(){
                UserService.getUsersByEvent(this.event.id).then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        if(response.status == 200){
                            this.users = response.data;
                        }
                    },
                    error => {
                        this.showToast(this.$t('error'), error.data,'danger');
                    }
                );

                if(this.checkpoints.length == 0){
                    CheckpointService.getAllCheckpoints(this.event.id).then(
                        resp => {
                            var response = resp.status ? resp : resp.response; 
                            if(response.status==200){
                                this.checkpoints = response.data;
                            }
                        }
                    );
                }
            },
            updateUser : function(user) {
                if(user.isRobotUser){
                    this.$refs.updatePinRobotModal.showModal();
                    this.$refs.updatePinRobotModal.user=user;
                } 
                else {
                    this.$refs.updatePinUserModal.showModal();
                    this.$refs.updatePinUserModal.user=user;
                }
            },
            exportPDF: function(){
                var robots = [];
                for (let i = 0; i < this.users.length; i++){
                    if (this.users[i].isRobotUser) {
                        robots.push(this.users[i]);
                    }
                }
                this.$refs.robotPdf.users = robots;
                this.$refs.robotPdf.checkpoints = this.checkpoints;

                this.$refs.AddUser.onStartExportPDF();
                this.$refs.robotPdf.createPDF();
            }
        }
    }
</script>

<style scoped>
    .card {
        overflow: visible;
    }
    .k4-deviceUserCard {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }
    .k4-deviceUserCard:hover {
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }
    .k4-robotIcon {
        color: #598cd9;
    }
</style>