<template>
    <b-modal v-if="isShowModal" v-model="isShowModal" v-bind:title="$t('devicesLogsDownloadCenter')" size="lg">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="filesPerPage" aria-controls="my-table" />
        
        <b-table sort-by="date" :sort-desc="true" :sort-compare-options="{ numeric: true, sensitivity: 'base' }" sort-icon-left responsive outlined striped hovers show-empty :items="files" :fields="tableFields" :per-page="filesPerPage" :current-page="currentPage">
            <!-- Texts to show when the table is empty -->
            <template #empty>
                <div class="text-center my-3">{{ $t("noDataToDisplay") }}</div>
            </template>
            <template #emptyfiltered>
                <div class="text-center my-3">{{ scope.emptyFilteredText }}</div>
            </template>

            <template v-slot:cell(date)="data">
                <div class="text-nowrap">{{this.$DateTimeService.GetDateFromISO(data.value, true)}}</div>
            </template>
            <template v-slot:cell(checkpointsNames)="data">
                <ul>
                    <li :key="checkpoint" v-for="checkpoint in data.value">{{ checkpoint }}</li>
                </ul>
            </template>
            <template v-slot:cell(download)="row">
                <div class="text-nowrap">
                    <b-button variant="primary" class="btn-sm" @click="download(row.item)"><font-awesome-icon icon="download" size="1x" class="mx-1" /></b-button>
                </div>
            </template>
        </b-table>

        <template #modal-footer>
            <div class="w-100">
                <button type="button" @click="isShowModal = false" class="btn btn-outline-secondary mx-1">{{$t("close")}}</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import EventService from '../../services/event.service';
    import utilMixin from '../../mixins/utils.mixins';

    export default {
        name : 'modalLogs',
        mixins: [utilMixin],
        components: {},
        data : function(){
            return {
                isShowModal : false,
                files: [],
                filesPerPage: 20,
                currentPage: 1
            };
        },
        computed: {
            eventId : function() {
                return this.$route.params.id;
            },
            tableFields: function() {
                return [
                    { key : 'deviceId', label: "Device Id", sortable: true},
                    { key : 'date', label: "Date", sortable: true },
                    { key : 'checkpointsNames', label : this.$t("checkpoints"), sortable: false, thClass : 'text-center'},
                    { key : 'download', label: this.$t("download"), sortable: false, thClass : 'text-center', tdClass : 'text-center'}
                ];
            },
            totalRows: function() {
                return this.files.length;
            }
        },
        created: function(){
            EventService.getAllLogFiles(this.eventId).then(
                resp => {
                    var response = resp.status ? resp : resp.response;
                    if(response.status == 200){
                        this.files = response.data;
                    } 
                    else {
                        
                        this.showToast(this.$t('error'), response.data, 'danger');
                    }
                }
            ).catch(error => {
                this.showToast(this.$t('error'), error);
            });
        },
        methods:{
            showModal: function() {
                this.isShowModal = true;
            },
            download: function(fileToDownload){
                console.log(fileToDownload);
                
                EventService.getLogFileContent(this.eventId, fileToDownload.date, fileToDownload.deviceId).then(
                    resp => {
                        var response = resp.status ? resp : resp.response;
                        if(response.status == 200){
                            // Create a Blob object with the text content
                            const blob = new Blob([response.data], { type: 'text/plain' });

                            // Create a URL for the Blob
                            const url = window.URL.createObjectURL(blob);

                            // Create a link element to trigger the download
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = fileToDownload.deviceId + "_" + fileToDownload.date.split("T")[0] + ".txt";

                            // Trigger a click event on the link to start the download
                            a.click();

                            // Clean up by revoking the Object URL
                            window.URL.revokeObjectURL(url);
                        } 
                        else {
                            
                            this.showToast(this.$t('error'), response.data, 'danger');
                        }
                    }
                ).catch(error => {
                    this.showToast(this.$t('error'), error);
                });
            }
        }
    }
</script>

<style scoped>
</style>