<template>
  <div class="h-100 wrapper" v-if="!isLogin">
    <MainHeader v-on:toggle="showAside = !showAside" :value="isMenuDrawerOpened" @on-menu-icon-click="onMenuIconClick" />
    <div class="page">
      <main class="d-flex">
        <MainMenu :value="isMenuDrawerOpened" />
        <article id="main" class="w-100 h-100 d-flex flex-column">
          <router-view class="px-4 flex-fill" />

          <footer class="k4-footer mt-3 d-flex align-items-center bg-white px-3 py-4 w-100">
            <div class="row w-100">
              <div class="py-1 col-xs-12 col-md-6 d-flex align-items-center">
                <img src="./assets/key4events_logo_text_blue.svg" />
                <p class="k4-footer__copyright mx-2 my-auto">@{{new Date().getFullYear()}} Tous droits réservés</p>
              </div>
              <div class="py-1 col-xs-12 col-md-6">
                <ul class="list-unstyled d-md-flex align-items-center justify-content-end">
                  <li><a href="#" class="k4-footer__link mx-1 text-primary-dark text-decoration-underline">Marque</a></li>
                  <li><a href="#" class="k4-footer__link mx-1 text-primary-dark text-decoration-underline">Déclaration de confidentialité des informations</a></li>
                  <li><a href="#" class="k4-footer__link mx-1 text-primary-dark text-decoration-underline">Conditions de vente</a></li>
                  <li><a href="#" class="k4-footer__link mx-1 text-primary-dark text-decoration-underline">Mentions légales</a></li>
                </ul>
              </div>
            </div>
          </footer>
        </article>
      </main>

      <OverlayMenuSmall v-if="isSmallScreen" v-show="showAside" v-model="showAside" />
      <AsideComponent v-else v-show="showAside" v-model="showAside"></AsideComponent>
    </div>
  </div>
  <div v-else class="h-100">
    <router-view />
  </div>
</template>

<script>
  import MainHeader from "./components/MainHeader"
  import MainMenu from "./components/MainMenu"
  import AsideComponent from './components/AsideComponent.vue';
  import OverlayMenuSmall from './components/small_screens/OverlayMenuSmall';
  import { mapState } from "vuex";

  export default {
    name: "App",
    components: { MainHeader, MainMenu, AsideComponent, OverlayMenuSmall },
    data: function () {
        return {
            showAside: false,
            isMenuDrawerOpened: false
        }
    },
    mounted() {
      window.addEventListener('resize', this.handleWindowResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleWindowResize);
    },
    methods: {
      onMenuIconClick(){
        this.isMenuDrawerOpened = !this.isMenuDrawerOpened;
      },
      handleWindowResize() {
        this.$store.commit('setIsSmallScreen', window.innerWidth <= 768);
      }
    },
    computed: {
      ...mapState(["isSmallScreen"]),
      isLogin : function() {
        return this.$route.name == 'Login';
      }
    }
  }
</script>

<style scoped>
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #main {
    opacity: 1;
    background-image: url('./assets/background_nav.svg');
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    background-size: cover; /* Set background size to cover and resize with zoom */
  }
  .k4-footer__copyright {
    color: #0003338A; 
    font-size: 0.8em;
  }
  .k4-footer__link {
    font-size: 0.9em;
  }
</style>
