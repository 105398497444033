<template>
    <transition name="oppening">
        <aside class="profile opened">
            <div class="w-100 h-100 p-md-5">
                <div v-on:click="close" class="close-profile pointer-click d-flex flex-fill justify-content-end">
                    <font-awesome-icon icon="times" class="fa-2x align-bottom text-black" />
                </div>
                
                <ProfileInfo />

                <div class="my-3">
                    <h4 class="d-md-inline-block line line-white my-1 text-primary-dark"><font-awesome-icon icon="language" class="mr-2 text-success" style="font-size: 22px;"/>{{ $t('language') }}</h4>
                    <LanguageSelector backgroundColorClass="bg-success" />
                </div>

                <button v-on:click="logout" type="button" class="my-5 btn btn-secondary btn-block">
                    <span><font-awesome-icon icon="sign-out-alt" class="k4-userMenu__container__logout__icon" /> {{ $t("disconnect") }}</span>
                </button>
            </div>
        </aside>
    </transition>
</template>

<script>
    import ProfileInfo from "../users/ProfileInfo.vue"
    import LanguageSelector from "../users/LanguageSelector.vue";

    export default {
        name: 'OverlayMenuSmall',
        components: { ProfileInfo, LanguageSelector },
        methods: {
            close: function () {
                this.$emit('input', false);
            },
            logout: function(){
                this.close();
                this.$store.dispatch('auth/logout');
                this.$router.replace({ name: 'Login' });
            }
        },
        computed: {
            userName: function(){
                return this.$store.state.auth.user ? this.$store.state.auth.user.name : null;
            },
            roles : function(){
                return this.$store.state.auth.user ? this.$store.state.auth.user.roles : null;
            }
        }
    };
</script>

<style scoped>
    .oppening-enter-active,
    .oppening-leave-active {
        transition: transform 0.5s;
    }

    .oppening-enter,
    .oppening-leave-to {
        transform: translateX(440px);
    }

    .oppening-enter-to,
    .oppening-leave {
        transform: translateX(0px);
    }
</style>