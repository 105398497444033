export const FRENCH_TRANSLATIONS = {
    connection: "Connexion à votre espace",
    login: "S'identifier",
    invalidLogin: "Erreur. Verifier votre identifiant et/ou votre mot de passe.",        
    password: "Mot de passe",
    identification: "Identifiant",
    title: "K4ACCESS - EVENT ADMINISTRATION BACK-OFFICE",
    name: "Nom",
    role: "Roles",
    email: "Email",
    pinCode: "Code pin ",
    events: "Événements",
    validatePassword: "Verif mot de passe",
    disconnect: "Déconnexion",
    menuUser: "BO autorisation des utilisateurs",
    menuTasks: "Tâches d'arrière-plan",
    selectEvent: "Sélectionnez votre événement",
    profile: "Profil",
    search: "Chercher...",
    startDate: "Date de début",
    endDate: "Date de fin",
    lastSync: "Dernière synchronisation",
    addEvent: "Ajouter un évènement",
    addUser: "Ajouter un utilisateur",
    importEvent: "Importer un événement",
    createUser: "Créer un utilisateur",
    userCreated: "Utilisateur créé",
    import: "Importer",
    create: "Créer",
    save: "Sauvegarder",
    edit: "Éditer",
    back: "Retour",
    cancel: "Annuler",
    close: "Fermer",
    download: "Telecharger",
    delete: "Effacer",
    update: "Mettre à jour",
    tasks: "Tâches",
    next: "Suivant",
    notProvided: "Non fourni",
    lastHistoric: "Derniers historiques",
    state: "État",
    duration: "Durée",
    message: "Message",
    notSynch: "pas synchronisé",
    syncInProgress: "synchronisation en cours",
    synchronized: "synchronisé",
    synchro: "Synchro",
    authorizeUsers: "Liste des utilisateurs autorisés",
    dashboard: "Tableau de bord",
    generalInformation: "Informations générales",
    onSiteStaff: "Personnel sur place",
    generalStats: "Statistiques générales",
    eventsStats: "Statistiques des points de contrôle",
    deviceUserlistEmpty: "la liste des utilisateurs de l'appareil est vide",
    checkpoints: "Points de contrôle",
    blueActivated: "bleu = activé",
    deleteUser: "Supprimer l'utilisateur",
    savedUser: "Utilisateur enregistré",
    comfirmDelete: "Voulez-vous vraiment supprimer",
    activateFor: "Points de contrôle actifs pour ",
    countCheckpoints: "Nombre de points de contrôle",
    countRegistered: "Badges connus",
    countPresent: "Ont été scannés",
    searchOn : "Recherche sur ID / LABEL / CATÉGORIE",
    searchOnCheck : "Recherche sur CODE-BARRE / NOM / DIRECTION / DATE / STATUT",
    searchOnBarcode : "Recherche sur CODE-BARRE / LABEL / TAGS",
    details: "Détails",
    config: "Configuration",
    detailsFor: "Détails pour ",
    attendees: "Participants",
    expected: "Attendu",
    loading: "Chargement",
    id: "Id",
    label:"Label",
    start:"Début",
    category:"Catégorie",
    accessControl:"Contrôle d'accès",
    checkedIn:"Enregistré",
    present:"Present",
    capacity:"Capacité",
    actions:"Actions",
    loadUnhauthCode: "Charger les codes-barres non autorisés",
    barcode: "Code-barre",
    lastUpdate: "Dernière mise à jour",
    openAccess: "Accès libre",
    notSet: "Non défini",
    passages: "Passages",
    tags: "Tags",
    authorized: "Autorisé",
    unauthorized: "Non autorisé",
    notAlive: "Pas vivant",
    location: "Lieu",
    isWaitForSignature: "Attend la signature",
    resultDisplayDuration: "Afficher les résultats",
    defaultNumberAllowed: "Nombre d'autorisations par défaut enregistré",
    syncFirst: "Synchroniser au début",
    syncFrequency: "Fréquence de synchronisation",
    accessUnknowBarcode: "Accès si code-barres inconnu",
    allowedRegistration: "Inscription autorisée",
    isInAndOut: "Est dedans et dehors",
    seconds: "secondes",
    people: "personnes",
    stop: "Arrêter",
    waiting: "En attente",
    run: "En cours",
    error: "Erreur",
    info: "Info",
    warning: "Attention",
    refreshStopped: "L'actualisation a été arrêtée.",
    syncRunningWait: "La synchronisation est en cours, veuillez patienter ...",
    synchroKnowbarcode: "La Synchro connaît le code barres",
    noUserSelected: "Aucun utilisateur sélectionné",
    confirmedDeleteUser: "Confirmation de la suppression de l'utilisateur",
    hasBeenDeleted: " a été supprimé.",
    hasBeenCreated: " a été créé.",
    selectAtLeastOneCp: "Veuillez sélectionner au moins un point de contrôle.",
    pleaseEnterName: "Veuillez entrer un nom.",
    pleaseEnterValidMail: "Veuillez saisir un e-mail valide.",
    passwordAreDifferent: "Les mots de passe sont différents.",
    pleaseSelectARole: "Veuillez sélectionner au moins un rôle.",
    saveCheckpoint: "Point de contrôle enregistré",
    checkIn:"Scans",
    scans:"Scans",
    date:"Date",
    photoDisplay:"Affichage photo",
    photoDisplayNone:"Aucun",
    photoDisplayPreload:"Pré-chargé",
    photoDisplayOnLoad:"Au chargement",
    statistics:"Statistiques",
    statisticsMore:"Plus de données",
    statisticsExport:"Exporter toutes les données",
    rushHour: "Heures d'affluences",
    rushCheckpoint: "Sélection des checkpoints",
    rushRemoveSelection: "Supprimer la sélection",
    selectAll: "Tout sélectionner",
    eventConfig: "Configuration de l'évènement - ",
    timezone: "Fuseau horaire",
    timezoneSelect: "Sélection du fuseau horaire",
    saveEvent: "Événement sauvegardé",
    pdfBanner: "Bannière PDF ",
    pdfBannerInfo: "(max: 725x150 px)",
    RobotUserEnable: "Activer les utilisateurs robot",
    UserType: "Type d'utilisateur",
    RegularUser: "Utilisateur normal",
    RobotUser: "Utilisateur robot",
    pleaseSelect: "Veuillez sélectionnez ...",
    directionIN:"ENTRÉE",
    directionOUT:"SORTIE",
    dayOfWork :"Jour d'exploitation",
    export: "Exporter",
    exportScansRegister : "Publier Scans",
    checkpointlistEmpty: "la liste des points de contrôle est vide",
    end: "Fin",
    warningTestEvent: "Attention: Cet évènement est actuellement en phase de 'test'.",
    productionMode: "Mode Production",
    keepData: "Garder toutes les données",
    deleteData: "Supprimer toutes les données",
    productionModePopUp1: "Êtes-vous certain de vouloir passer en mode production ? (Toutes les données seront effacées)",
    productionModePopUp2: "Êtes-vous certain de vouloir passer en mode production ? (Toutes les données seront conservées)",
    ok: "Ok",
    importFromList: "Depuis une liste",
    importManual: "Manuel",
    eventId: "Event ID",
    eventHash: "API Key",
    eventUrl: "API URL",
    success: "Success",
    startExportPDF: "Début de l'export PDF...",
    endExportPDF: "Le fichier PDF a été téléchargé",
    welcome: "bienvenue",
    on: "sur",
    group: "Group",
    createNewGroup: "Ajouter un nouveau groupe",
    searchGroup: "Tapez pour rechercher ou ajouter un groupe",
    noDataToDisplay: "Pas de données à afficher",
    allowManualAdditionalScans: "Autoriser la saisie manuelle d'un nombre de passages",
    language: "Langue",
    status: "Statut",
    unknownBarcode: "Code-Barre Inconnu",
    refused: "Refusé",
    quantity: "Quantité",
    devicesLogsDownloadCenter: "Centre de téléchargement des journaux des appareils"
}