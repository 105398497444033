<template>
    <b-modal class="modal" v-model="modalShow" size="xl" v-bind:title="$t('detailsFor') + ' : ' + title" v-bind:cancel-title="$t('download')" v-bind:ok-title="$t('close')" @cancel="download">
        <div class="k4-table-header my-3">
            <div class="d-flex align-items-center">
                <div class="input-group">
                    <input id="searchInput" type="text" v-model="searchQuery" v-on:keyup="filterItems" class="form-control" v-bind:placeholder="$t('searchOnCheck')" aria-label="Search" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">
                            <font-awesome-icon :icon="['fas','search']" size="1x" />
                        </span>
                    </div>
                </div>

                <b-pagination class="mx-1 my-0" v-model="currentPage" :total-rows="rows" :per-page="amount" aria-controls="my-table" />
            </div>
            <div v-if="isFetchingData" class="w-100 d-flex justify-content-center align-items-center my-3"><span class="spinner-border spinner-border-sm mx-2"></span>{{ $t('loading') }}</div>
        </div>

        <b-table responsive sort-icon-left striped hovers show-empty sort-by="dateCheck" :sort-desc="true" :sort-compare-options="{ numeric: true, sensitivity: 'base' }" :items="filteredCheckEntries" :fields="tableFields" :per-page="amount" :current-page="currentPage">
            <!-- Texts to show when the table is empty -->
            <template #empty>
                <div class="text-center my-3">{{ $t("noDataToDisplay") }}</div>
            </template>
            <template #emptyfiltered>
                <div class="text-center my-3">{{ scope.emptyFilteredText }}</div>
            </template>

            <template v-slot:cell(barcode)="data">
                <div class="text-left"><span v-if="data.value" style=" display:inline-block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 25ch;" >{{data.value}}</span></div>
            </template>
            <template v-slot:cell(barcodeName)="data">
                <div v-if="data.item.isUnknownBarcode" class="badge badge-danger py-1 px-2">{{data.value}}</div>
                <div v-else>{{data.value}}</div>
            </template>
            <template v-slot:cell(dateCheck)="data">
                <div class="text-nowrap"><span v-if="data.value">{{this.$DateTimeService.GetDateTimeFromISO(data.value, true)}}</span></div>
            </template>
            <template v-slot:cell(status)="data">
                <div class="text-right">
                    <span v-if="data.value == 'AUTHORIZED'" class="badge badge-success py-1 px-2">{{ this.$t("authorized") }}</span>
                    <span v-else class="badge badge-danger py-1 px-2">{{ this.$t("refused") }}</span>
                </div>
            </template>
        </b-table>

        <template v-slot:modal-footer="{ cancel }">
            <div class="d-flex justify-content-end align-items-center">
                <div v-if="checkEntries.length > 0" class="mr-2">
                    <vue-excel-xlsx class= "btn btn-excel"
                        :data="filteredCheckEntries"
                        :columns="excelFields"
                        :filename="'CheckEntries-' + title"
                        :sheetname="'CheckEntries'"
                        :disabled="isFetchingData"
                        >
                        <font-awesome-icon icon="file-excel" /><span>Download Excel</span>
                    </vue-excel-xlsx>
                </div>
                <button @click="cancel()" class="btn btn-outline-secondary">{{$t('close')}}</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import CheckEntriesService from '../../services/checkentries.service';
import utilMixin from '../../mixins/utils.mixins';

export default {
    name : "modalCheckEntriesList",
    mixins: [utilMixin],
    data: function(){
        return {
            checkpointId : 0,
            isAllowManualAdditionalScans : false,
            barcode : '',
            type : '',
            title : '',
            checkEntries : [],
            searchQuery:'',
            filteredCheckEntries: [],
            modalShow : false,
            tableFields: [
                {key : 'barcode', label: this.$t("barcode"), sortable : true},
                {key : 'barcodeName', label: this.$t("name"), sortable : true},
                {key : 'quantity', label: this.$t("quantity"), sortable : true},
                {key : 'direction', label: this.$t("direction"), sortable : true},
                {key : 'dateCheck', label: this.$t("date"), sortable : true},
                {key : 'status', label: this.$t("status"), sortable : true}
            ],
            excelFields : [
                    {
                        label: "Id",
                        field: "id",
                    },
                    {
                        label: "Barcode",
                        field: "barcode"
                    },
                    { 
                        label: "Quantity",
                        field: "quantity"
                    },
                    {
                        label: "Direction",
                        field: "direction",
                    },
                    {
                        label: "Date",
                        field: "dateCheck",
                    },
                    {
                        label: "Status",
                        field: "status",
                    },
                    {
                        label: "Scanner User Name",
                        field: "scannerUserName",
                    },
                    {
                        label: "Barcode Name",
                        field: "barcodeName",
                    }
                ],
            page : 0,
            amount : 200,
            currentPage : 1,
            isFetchingData: false
        }    
    },
    computed : {
        eventId : function(){
            return this.$route.params.id;
        },
        rows() {
            return this.filteredCheckEntries.length
        }
    },
    watch : {
        modalShow: function(val){
            if(!val){ 
                this.checkpointId = 0; 
                this.barcode = '';
                this.checkEntries = []; 
                this.searchQuery = '';
                this.filteredCheckEntries = [];
            }
        },
        checkpointId: function(val){
            if(val > 0){ this.refreshCheckEntries(); }
        }
    },
    methods: {
        showModal : function() {
            const hasQuantityField = this.tableFields.some(item => item.key === "quantity");
            const hasQuantityKey = this.excelFields.some(item => item.field === "quantity");
            if(this.isAllowManualAdditionalScans){
                if(!hasQuantityField){
                    this.tableFields.splice(2, 0, {key : 'quantity', label: this.$t("quantity"), sortable : true});
                }
                
                if(!hasQuantityKey){
                    this.excelFields.splice(2, 0, { label: "Quantity", field: "quantity" });
                }
            }
            else {
                if(hasQuantityField){
                    this.tableFields = this.tableFields.filter(item => item.key !== "quantity");
                }
                
                if(hasQuantityKey){
                    this.excelFields = this.excelFields.filter(item => item.field !== "quantity");
                }
            }

            this.modalShow = true
        },
        refreshCheckEntries(){
            if(this.type.toUpperCase() == 'CHECKPOINT') { this.refreshCheckEntriesByCheckpoint(); }
            else if(this.type.toUpperCase() == 'BARCODE') { this.refreshCheckEntriesByBarcode(); }
        },
        refreshCheckEntriesByCheckpoint(p, a){
            if(this.checkpointId<=0){ 
                this.modalShow = false; return; 
            }

            if (this.page == 0){
                this.isFetchingData = true;
                this.canAskUnauthorized = false;
            }
            if(p){
                this.page = p;
            }
            if(a){
                this.amount = a;
            }

            var model  = {
                page : this.page,
                amount : this.amount
            }

            CheckEntriesService.getByCheckpoint(model, this.eventId, this.checkpointId).then(
                resp =>{
                    var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        response.data.forEach(element => {
                            if(element.isUnknownBarcode){
                                element.barcodeName = this.$t("unknownBarcode");
                            }
                        });

                        this.checkEntries = [...this.checkEntries, ...response.data];
                        this.filterItems();
                        if(response.data.length == this.amount){
                            this.page = this.page + 1;
                            this.refreshCheckEntriesByCheckpoint(this.page, this.amount);
                        }
                        else {
                            this.page = 0;
                            this.isFetchingData = false;
                        }
                    } 
                    else {
                        this.showToast(this.$t('error'), response.data, 'danger');
                        this.isFetchingData = false;
                    }
                }
            ).catch(
                error => {
                    this.showToast(this.$t('error'), error, 'danger');
                    this.isFetchingData = false;
                }
            );
        },
        refreshCheckEntriesByBarcode(){
            if(this.barcode){ 
                this.modalShow = false; return; 
            }

            this.isFetchingData = true;
            CheckEntriesService.getByBarcode(this.eventId, this.barcode).then(
                resp =>{
                    var response = resp.status ? resp : resp.response; 
                    if(response.status == 200){
                        this.checkEntries = response.data;
                        this.filterItems();
                        this.isFetchingData = false;
                    } 
                    else {
                        this.showToast(this.$t('error'), response.data, 'danger');
                        this.isFetchingData = false;
                    }
                }
            ).catch(
                error => {
                    this.showToast(this.$t('error'), error, 'danger');
                    this.isFetchingData = false;
                }
            );
        },
        filterItems : function(){
            this.filteredCheckEntries = this.checkEntries.filter(item =>{
                var itemText = item.barcode + "|" + item.direction + "|" + item.barcodeName + "|" + item.dateCheck + "|" + item.status;
                return itemText.toLowerCase().includes(this.searchQuery.toLowerCase())
            });
        }
    }
}


</script>

<style>
    .k4-table-header {
        position: sticky;
        top: 0;
        z-index: 1;
    }
</style>
